import React, { useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode'
import { AuthContext } from '../context/auth-context';

const TokenExpirationHandler = () => {
  const auth = useContext(AuthContext);
  
  useEffect(() => {
    let logoutTimer;

    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const expirationTime = decodedToken.exp * 1000;
          const currentTime = Date.now();
          
          if (expirationTime <= currentTime) {
            auth.logout();
          } else {
            logoutTimer = setTimeout(() => {
              auth.logout();
            }, expirationTime - currentTime);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          auth.logout();
        }
      }
    };

    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 60000);
    return () => {
      clearInterval(intervalId);
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, [auth]);

  return null;
};

export default TokenExpirationHandler;
