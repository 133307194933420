import React from 'react';
import { Star, ArrowUp, ArrowDown, ChevronDown, ChevronRight } from 'lucide-react';
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { Card, CardContent } from './ui/card';

const MetricCard = ({ label, value, trending, suffix = '' }) => (
  <div>
    <div className="text-sm text-gray-500">{label}</div>
    <div className="flex items-center">
      <span className="text-lg font-semibold text-gray-900">
        {value}{suffix}
      </span>
      {typeof trending === 'number' && trending !== 0 && (
        <span className={`ml-2 flex items-center text-sm ${
          trending >= 0 ? 'text-green-600' : 'text-red-600'
        }`}>
          {trending >= 0 ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
          {Math.abs(trending)}%
        </span>
      )}
    </div>
  </div>
);

const CampaignCard = ({ campaign, isExpanded, onToggleExpand }) => {
  const {
    leads = { total: 0, mql: 0, sql: 0, trending: 0 },
    opportunities = { total: 0, trending: 0 },
    closed = { winRate: 0, value: 0 },
    engagement = { emailOpenRate: 0 }
  } = campaign.metrics || {};

  const formatValue = (value) => {
    if (typeof value !== 'number') return '0';
    return value.toLocaleString();
  };

  const formatCurrency = (value) => {
    if (typeof value !== 'number') return '$0.0M';
    return `$${(value / 1000000).toFixed(1)}M`;
  };

  return (
    <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
      <div 
        className="p-6 hover:bg-gray-50 cursor-pointer"
        onClick={() => onToggleExpand(campaign.campaign_id)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                // Toggle follow status
              }}
              className={`text-yellow-400 hover:text-yellow-500 ${
                campaign.isFollowed ? 'fill-current' : ''
              }`}
            >
              <Star className="w-5 h-5" />
            </button>
            <div>
              <h3 className="text-lg font-medium text-gray-900">{campaign.name}</h3>
              <div className="flex items-center space-x-3 mt-1">
                <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  campaign.status === 'active' 
                    ? 'bg-green-100 text-green-800'
                    : campaign.status === 'completed'
                    ? 'bg-orange-100 text-orange-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {campaign.status}
                </span>
                <span className="text-sm text-gray-500">{campaign.dateRange}</span>
                <span className="text-sm text-gray-500">Owner: {campaign.owner}</span>
                {campaign.type && (
                  <span className="text-sm text-gray-500 bg-blue-50 px-2 py-1 rounded">
                    {campaign.type}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-8">
            <div className="grid grid-cols-4 gap-8">
              <MetricCard 
                label="Leads" 
                value={formatValue(leads.total)}
                trending={leads.trending}
              />
              <MetricCard 
                label="Opportunities" 
                value={formatValue(opportunities.total)}
                trending={opportunities.trending}
              />
              <MetricCard 
                label="Win Rate" 
                value={formatValue(closed.winRate)}
                suffix="%"
              />
              <MetricCard 
                label="Engagement" 
                value={formatValue(engagement.emailOpenRate)}
                suffix="%"
              />
            </div>
            
            <button>
              {isExpanded ? (
                <ChevronDown className="w-5 h-5 text-gray-400" />
              ) : (
                <ChevronRight className="w-5 h-5 text-gray-400" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="border-t border-gray-200 p-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Pipeline Performance</h4>
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-blue-50 rounded-lg p-4">
                    <p className="text-sm text-blue-700">MQLs</p>
                    <p className="text-lg font-semibold text-blue-900">
                      {formatValue(leads.mql)}
                    </p>
                  </div>
                  <div className="bg-purple-50 rounded-lg p-4">
                    <p className="text-sm text-purple-700">SQLs</p>
                    <p className="text-lg font-semibold text-purple-900">
                      {formatValue(leads.sql)}
                    </p>
                  </div>
                  <div className="bg-green-50 rounded-lg p-4">
                    <p className="text-sm text-green-700">Closed</p>
                    <p className="text-lg font-semibold text-green-900">
                      {formatCurrency(closed.value)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Performance Trend</h4>
              <div className="h-48">
                {campaign.trend && campaign.trend.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={campaign.trend}>
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Line 
                        type="monotone" 
                        dataKey="value" 
                        stroke="#3B82F6" 
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-500">
                    No trend data available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignCard;
