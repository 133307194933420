import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import { useHttpClient } from '../../shared/hooks/http-hook';

const getEventBgColor = (type) => {
  switch (type) {
    case 'meeting':
      return 'bg-blue-50';
    case 'deadline':
      return 'bg-purple-50';
    case 'conference':
      return 'bg-green-50';
    case 'webinar':
      return 'bg-orange-50';
    case 'review':
      return 'bg-pink-50';
    default:
      return 'bg-gray-50';
  }
};

const getEventIconColor = (type) => {
  switch (type) {
    case 'meeting':
      return 'text-blue-600';
    case 'deadline':
      return 'text-purple-600';
    case 'conference':
      return 'text-green-600';
    case 'webinar':
      return 'text-orange-600';
    case 'review':
      return 'text-pink-600';
    default:
      return 'text-gray-600';
  }
};

const UpcomingEventsPreview = ({ teamMembers, auth, refreshTrigger }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = useHttpClient();

  const fetchUpcomingEvents = async () => {
    setIsLoading(true);
    try {
      const eventPromises = teamMembers.map(member =>
        sendRequest(
          `${process.env.REACT_APP_API_URL}/events/user/${member.user_id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        ).catch(err => {
          console.error(`Error fetching events for user ${member.user_id}:`, err);
          return [];
        })
      );

      const allUserEvents = await Promise.all(eventPromises);
      
      const eventMap = new Map();
      const now = new Date();
      
      allUserEvents.flat().forEach(event => {
        // Handle both single-day and multi-day events
        const startDate = new Date(`${event.startDate}${event.time ? ` ${event.time}` : ''}`);
        if (startDate >= now && !eventMap.has(event.event_id)) {
          eventMap.set(event.event_id, {
            id: event.event_id,
            title: event.title,
            startDate: event.startDate,
            endDate: event.endDate,
            time: event.time,
            type: event.type,
            created_by: event.created_by
          });
        }
      });

      // Sort by start date and get next 2 upcoming events
      const sortedEvents = Array.from(eventMap.values())
        .sort((a, b) => {
          const dateA = new Date(`${a.startDate}${a.time ? ` ${a.time}` : ''}`);
          const dateB = new Date(`${b.startDate}${b.time ? ` ${b.time}` : ''}`);
          return dateA - dateB;
        })
        .slice(0, 2);

      setEvents(sortedEvents);
    } catch (err) {
      console.error('Error fetching upcoming events:', err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (teamMembers?.length > 0 && auth.token) {
      fetchUpcomingEvents();
    }
  }, [teamMembers, auth.token, refreshTrigger]);

  const formatEventTime = (event) => {
    const startDate = new Date(`${event.startDate}${event.time ? ` ${event.time}` : ''}`);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let dateString = '';
    if (startDate.toDateString() === today.toDateString()) {
      dateString = 'Today';
    } else if (startDate.toDateString() === tomorrow.toDateString()) {
      dateString = 'Tomorrow';
    } else {
      dateString = startDate.toLocaleDateString();
    }

    // Add time for single-day events
    if (event.time) {
      dateString += ` at ${formatTime(event.time)}`;
    }

    // Add end date for multi-day events
    if (event.endDate && event.endDate !== event.startDate) {
      dateString += ` - ${new Date(event.endDate).toLocaleDateString()}`;
    }

    return dateString;
  };

  const formatTime = (time) => {
    return new Date(`2000-01-01 ${time}`).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-4">
        <div className="text-sm text-gray-500">Loading events...</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {events.length > 0 ? (
        events.map((event) => (
          <div key={event.id} className="flex items-center space-x-3">
            <div className={`w-10 h-10 rounded-lg flex items-center justify-center ${getEventBgColor(event.type)}`}>
              <Calendar className={`w-5 h-5 ${getEventIconColor(event.type)}`} />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">
                {event.title}
              </p>
              <p className="text-xs text-gray-500">
                {formatEventTime(event)}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="text-sm text-gray-500 text-center py-4">
          No upcoming events
        </div>
      )}
    </div>
  );
};

export default UpcomingEventsPreview;
