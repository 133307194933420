import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from './auth-context';

export const NotificationContext = createContext({
  notifications: [],
  unreadNotifications: 0,
  fetchNotifications: () => {},
  setNotifications: () => {},
  setUnreadNotifications: () => {},
  markAsRead: () => {},
  markAllAsRead: () => {},
  fetchUploadHistoryFromNotification: () => {}
});

export const NotificationProvider = ({ children }) => {
  const { user_id, token } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [fetchUploadHistoryCallback, setFetchUploadHistoryCallback] = useState(null);

  const fetchNotifications = useCallback(async () => {
    if (!user_id || !token) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${user_id}/unread`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
      const data = await response.json();
      
      if (Array.isArray(data)) {
        const sortedNotifications = data.sort((a, b) => 
          new Date(b.date) - new Date(a.date)
        );
        
        setNotifications(sortedNotifications);
        setUnreadNotifications(sortedNotifications.length);
      } else {
        console.error("Unexpected API response format:", data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [user_id, token]);

  const addNewNotification = useCallback((newNotification) => {
    setNotifications(prev => {
      const exists = prev.some(n => 
        n.notification_id === newNotification.notification_id
      );
      
      if (!exists) {
        return [newNotification, ...prev];
      }
      return prev;
    });
    
    setUnreadNotifications(prev => prev + 1);
  }, []);

  const markAsRead = useCallback(async (notificationId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/read`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }

      setNotifications(prev => 
        prev.filter(notification => notification.notification_id !== notificationId)
      );

      setUnreadNotifications(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toast.error("Failed to mark notification as read");
    }
  }, [token]);

  const markAllAsRead = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${user_id}/mark-all-read`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to mark all notifications as read');
      }

      setNotifications([]);
      setUnreadNotifications(0);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      toast.error('Failed to mark all notifications as read');
    }
  }, [user_id, token]);
  
  useEffect(() => {
    if (!user_id || !token) {
      return;
    }

    let ws = null;
    let reconnectTimeout = null;
    let heartbeatInterval = null;

    const connect = () => {
      const WEBSOCKET_ENDPOINT = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT}/?user_id=${user_id}&auth_token=${token}`;
      
      ws = new WebSocket(WEBSOCKET_ENDPOINT);

      ws.onopen = () => {
        console.log('WebSocket connected');
        heartbeatInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: 'ping' }));
          }
        }, 30000);
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.action === 'enrichment_complete') {
          toast.success(message.message);

          const newNotification = {
            notification_id: `ws-${new Date().getTime()}`,
            user_id: user_id,
            message: message.message,
            type: 'Download Ready',
            date: new Date().toISOString(),
            metadata: {}
          };

          addNewNotification(newNotification);
          
          if (fetchUploadHistoryCallback) {
            fetchUploadHistoryCallback();
          }
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket closed:', event.code);
        clearInterval(heartbeatInterval);
        
        if (event.code !== 1000) {
          reconnectTimeout = setTimeout(connect, 5000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    };

    connect();

    return () => {
      if (ws) {
        ws.close(1000, 'Component unmounting');
      }
      clearInterval(heartbeatInterval);
      clearTimeout(reconnectTimeout);
    };
  }, [user_id, token, addNewNotification, fetchUploadHistoryCallback]);

  useEffect(() => {
    if (user_id && token) {
      fetchNotifications();
      const refreshInterval = setInterval(fetchNotifications, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [user_id, token, fetchNotifications]);

  return (
    <NotificationContext.Provider value={{ 
      notifications, 
      unreadNotifications, 
      fetchNotifications, 
      markAsRead,
      markAllAsRead,
      fetchUploadHistoryFromNotification: setFetchUploadHistoryCallback,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};
