import React, { useState, useCallback, useEffect } from "react";
import { jwtDecode } from 'jwt-decode'

export const AuthContext = React.createContext({
  isLoggedIn: false,
  token: null,
  user_id: null,
  role: null,
  username: null,
  team: null,
  division: null,
  time_zone: null,
  show_welcome_banner: null,
  isAuthReady: false,
  login: (user_id, token, role, username, team, division, time_zone, show_welcome_banner) => {},
  logout: () => {},
  setRole: (role) => {},
  setUsername: (username) => {},
  setTeam: (team) => {},
  setDivision: (division) => {},
  setTimeZone: (time_zone) => {},
  setShowWelcomeBanner: (show_welcome_banner) => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [team, setTeam] = useState(null);
  const [division, setDivision] = useState(null);
  const [time_zone, setTimeZone] = useState(null);
  const [show_welcome_banner, setShowWelcomeBanner] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setRole(null);
    setUsername(null);
    setTeam(null);
    setDivision(null);
    setTimeZone(null);
    setShowWelcomeBanner(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    localStorage.removeItem("team");
    localStorage.removeItem("division");
    localStorage.removeItem("time_zone");
    localStorage.removeItem("show_welcome_banner");
    setIsAuthReady(false);
  }, []);

  const login = useCallback(
    (user_id, token, role, username, team, division, time_zone, show_welcome_banner) => {
      setToken(token);
      setUserId(user_id);
      setRole(role);
      setUsername(username);
      setTeam(team);
      setDivision(division);
      setTimeZone(time_zone);
      setShowWelcomeBanner(show_welcome_banner);
      localStorage.setItem("token", token);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("role", role);
      localStorage.setItem("username", username);
      localStorage.setItem("team", team);
      localStorage.setItem("division", division);
      localStorage.setItem("time_zone", time_zone);
      localStorage.setItem("show_welcome_banner", show_welcome_banner);
      setIsAuthReady(true);
    },
    []
  );

  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem("token");
      const storedUserId = localStorage.getItem("user_id");
      const storedRole = localStorage.getItem("role");

      if (storedToken && storedUserId) {
        try {
          const decodedToken = jwtDecode(storedToken);
          const expirationTime = decodedToken.exp * 1000;

          if (expirationTime <= Date.now()) {
            logout();
            setIsAuthReady(true);
          } else {
            setToken(storedToken);
            setUserId(storedUserId);
            setRole(storedRole);

            // Fetch user data from the backend
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/users/${storedUserId}`,
                {
                  headers: {
                    Authorization: `Bearer ${storedToken}`,
                  },
                }
              );
              if (!response.ok) {
                throw new Error('Failed to fetch user data');
              }
              const userData = await response.json();
              setUsername(userData.username);
              setTeam(userData.team);
              setDivision(userData.division);
              setTimeZone(userData.time_zone);
              setShowWelcomeBanner(userData.show_welcome_banner);
              setIsAuthReady(true);
            } catch (error) {
              console.error('Error fetching user data:', error);
              logout();
              setIsAuthReady(true);
            }
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          logout();
          setIsAuthReady(true);
        }
      } else {
        setIsAuthReady(true);
      }
    };

    initializeAuth();
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        user_id,
        role,
        username,
        team,
        division,
        time_zone,
        show_welcome_banner,
        isAuthReady,
        login,
        logout,
        setRole,
        setUsername,
        setTeam,
        setDivision,
        setTimeZone,
        setShowWelcomeBanner,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
