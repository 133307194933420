import React, { useState, useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../context/auth-context";
import "./FeedbackModal.css";

const FeedbackModal = ({ onClose }) => {
  const auth = useContext(AuthContext);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const MAX_CHARS = 300;

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleMessageChange = (e) => {
    const text = e.target.value;
    if (text.length <= MAX_CHARS) {
      setMessage(text);
      setCharacterCount(text.length);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const feedbackData = {
      type,
      message,
      rating,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feedback/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify(feedbackData),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }

      alert("Thank you for your feedback!");
      onClose();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert(
        "An error occurred while submitting your feedback. Please try again.",
      );
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box className="feedback-modal">
        <Box className="modal-header">
          <Typography variant="h6">Leadfeed Feedback</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit} className="modal-form">
          <FormControl fullWidth required sx={{ marginBottom: 2 }}>
            <InputLabel id="feedback-type-label">Type</InputLabel>
            <Select
              labelId="feedback-type-label"
              id="feedback-type"
              value={type}
              label="Type"
              onChange={handleTypeChange}
            >
              <MenuItem value="General Feedback">General Feedback</MenuItem>
              <MenuItem value="Feature Request">Feature Request</MenuItem>
              <MenuItem value="Report an Issue">Report an Issue</MenuItem>
              <MenuItem value="UI Feedback">UI Feedback</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Enter your message below"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            required
            value={message}
            onChange={handleMessageChange}
            error={characterCount === MAX_CHARS}
            helperText={`${characterCount}/${MAX_CHARS} characters`}
          />
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
              Rank your experience:
            </Typography>
            <Rating
              name="feedback-rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
