import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  MessageSquare,
  Calendar,
  FileUp,
  Users,
  Clock,
  Search,
  Target,
  TrendingUp,
  TrendingDown,
  FileSpreadsheet,
  UserCog,
  Star,
  ArrowUp,
  ArrowDown,
  ChevronDown,
  BarChart2,
  Mail,
} from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shared/components/UIElements/alert-dialog";
import { Card, CardHeader, CardContent } from "../../user/components/ui/card";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import TeamCalendarModal from "../components/TeamCalendarModal";
import UpcomingEventsPreview from "../components/UpcomingEventsPreview";
import TeamCampaigns from "../components/TeamCampaigns";
import TeamLeads from "../components/TeamLeads";
import defaultUserImage from "../../user/components/user.png";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const MetricCard = ({ label, value, trending, icon: Icon }) => (
  <Card className="w-full">
    <CardContent className="h-32 flex items-center justify-center p-6">
      <div className="flex flex-col items-center text-center pt-4">
        {Icon && (
          <div className="mb-2">
            <Icon className="w-6 h-6 text-blue-600" />
          </div>
        )}
        <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
        <div className="flex items-center space-x-2">
          <span className="text-2xl font-semibold text-gray-900">
            {typeof value === "number" ? value.toLocaleString() : value}
          </span>
          {trending != null && (
            <span
              className={`text-sm ${trending >= 0 ? "text-green-600" : "text-red-600"}`}
            >
              {trending > 0 ? "+" : ""}
              {trending}%
            </span>
          )}
        </div>
      </div>
    </CardContent>
  </Card>
);

const CampaignCard = ({ campaign }) => {
  // Destructure with default values to handle missing data gracefully
  const {
    name = "Unnamed Campaign",
    status = "unknown",
    dateRange = "No date range",
    owner = "Unassigned",
    metrics = {
      leads: { total: 0, trending: 0 },
      opportunities: { total: 0, trending: 0 },
      engagement: {
        emailOpen: 0,
        trending: 0,
        clickRate: 0,
      },
    },
  } = campaign;

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toLocaleString();
  };

  return (
    <div className="py-4 first:pt-0 last:pb-0">
      {/* Header Section */}
      <div className="flex items-start justify-between">
        <div className="flex items-start space-x-3">
          <div className="mt-1">
            <Star
              className={`h-4 w-4 ${
                campaign.isPriority
                  ? "text-yellow-400 fill-current"
                  : "text-gray-300"
              }`}
            />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h3 className="font-medium text-gray-900">{name}</h3>
              <span
                className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                  status === "active"
                    ? "bg-green-100 text-green-800"
                    : status === "completed"
                      ? "bg-blue-100 text-blue-800"
                      : "bg-gray-100 text-gray-800"
                }`}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            </div>
            <div className="mt-1 flex items-center space-x-2 text-sm text-gray-500">
              <span>{dateRange}</span>
              <span>•</span>
              <span>Owner: {owner}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="mt-4 grid grid-cols-3 gap-4">
        {/* Leads Metric */}
        <div className="flex items-center space-x-2">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Users className="h-4 w-4 text-blue-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {formatNumber(metrics.leads.total)}
              </span>
              {metrics.leads.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.leads.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.leads.trending > 0 ? "+" : ""}
                  {metrics.leads.trending}%
                </span>
              )}
            </div>
            <p className="text-xs text-gray-500">Total Leads</p>
          </div>
        </div>

        {/* Opportunities Metric */}
        <div className="flex items-center space-x-2">
          <div className="p-2 bg-purple-50 rounded-lg">
            <Target className="h-4 w-4 text-purple-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {formatNumber(metrics.opportunities.total)}
              </span>
              {metrics.opportunities.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.opportunities.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.opportunities.trending > 0 ? "+" : ""}
                  {metrics.opportunities.trending}%
                </span>
              )}
            </div>
            <p className="text-xs text-gray-500">Opportunities</p>
          </div>
        </div>

        {/* Engagement Metric */}
        <div className="flex items-center space-x-2">
          <div className="p-2 bg-green-50 rounded-lg">
            <Mail className="h-4 w-4 text-green-600" />
          </div>
          <div>
            <div className="flex items-center space-x-1">
              <span className="font-medium">
                {metrics.engagement.emailOpen}%
              </span>
              {metrics.engagement.trending !== 0 && (
                <span
                  className={`text-xs ${
                    metrics.engagement.trending >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {metrics.engagement.trending > 0 ? "+" : ""}
                  {metrics.engagement.trending}%
                </span>
              )}
            </div>
            <div className="flex items-center">
              <p className="text-xs text-gray-500">Email Engagement</p>
              {metrics.engagement.clickRate > 0 && (
                <span className="text-xs text-gray-400 ml-1">
                  ({metrics.engagement.clickRate}% CTR)
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeamHub = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [activeCampaign, setActiveCampaign] = useState("all");
  const [teamMembers, setTeamMembers] = useState([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalUploads, setTotalUploads] = useState(0);
  const [recentUploads, setRecentUploads] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [expandedCampaign, setExpandedCampaign] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [eventsRefreshTrigger, setEventsRefreshTrigger] = useState(0);
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const checkProfileCompletion = () => {
      const userTeam = localStorage.getItem("team");
      const userDivision = localStorage.getItem("division");

      if (!userTeam || !userDivision) {
        setShowProfileModal(true);
        return false;
      }
      return true;
    };

    const fetchTeamMembers = async () => {
      try {
        if (!checkProfileCompletion()) return;

        const userTeam = localStorage.getItem("team");
        const userDivision = localStorage.getItem("division");

        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );

        const filteredUsers = responseData
          .filter(
            (user) =>
              user.team === userTeam &&
              user.division === userDivision &&
              !user.api_account &&
              user.active,
          )
          .map((user) => ({
            ...user,
            avatar: user.image_url || defaultUserImage,
            status: user.status || "active",
          }));

        let accumulatedLeads = 0;
        let accumulatedUploads = 0;

        const usersWithActivity = await Promise.all(
          filteredUsers.map(async (user) => {
            try {
              const activityData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/users/activity/${user.user_id}`,
                "GET",
                null,
                {
                  Authorization: `Bearer ${auth.token}`,
                },
              );

              accumulatedLeads += activityData.lead_count || 0;
              accumulatedUploads += activityData.upload_count || 0;

              return {
                ...user,
                leads: activityData.lead_count || 0,
                uploads: activityData.upload_count || 0,
              };
            } catch (err) {
              console.error(
                `Error fetching activity for user ${user.user_id}:`,
                err,
              );
              return {
                ...user,
                leads: 0,
                uploads: 0,
              };
            }
          }),
        );

        setTotalLeads(accumulatedLeads);
        setTotalUploads(accumulatedUploads);
        setTeamMembers(usersWithActivity);
        fetchRecentUploads(filteredUsers);
        fetchTeamCampaigns(filteredUsers);
      } catch (err) {
        console.error("Error fetching team members:", err);
      }
    };

    if (auth.token) {
      fetchTeamMembers();
    }
  }, [sendRequest, auth.token]);

  const fetchRecentUploads = async (teamMembers) => {
    try {
      let accumulatedUploads = 0;

      const uploadsPromises = teamMembers.map(async (member) => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/uploads/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          );
          accumulatedUploads += responseData.length;
          return responseData;
        } catch (err) {
          console.error(
            `Error fetching uploads for user ${member.user_id}:`,
            err,
          );
          return [];
        }
      });

      const uploadsArrays = await Promise.all(uploadsPromises);
      const allUploads = uploadsArrays.flat();
      const sortedUploads = allUploads.sort(
        (a, b) => new Date(b.upload_date) - new Date(a.upload_date),
      );
      setRecentUploads(sortedUploads.slice(0, 5));
      setTotalUploads(accumulatedUploads);
    } catch (err) {
      console.error("Error fetching recent uploads:", err);
    }
  };

  const fetchTeamCampaigns = async (teamMembers) => {
    try {
      const campaignsPromises = teamMembers.map(async (member) => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/campaigns/user/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          );
          return responseData;
        } catch (err) {
          console.error(
            `Error fetching campaigns for user ${member.user_id}:`,
            err,
          );
          return [];
        }
      });

      const campaignsArrays = await Promise.all(campaignsPromises);
      const allCampaigns = campaignsArrays.flat();

      const campaignsById = {};
      allCampaigns.forEach((campaign) => {
        // Transform each campaign to match the expected structure
        campaignsById[campaign.campaign_id] = {
          ...campaign,
          // Since metrics is empty in the API response, provide default metrics
          metrics: {
            leads: {
              total: 0,
              trending: 0,
            },
            opportunities: {
              total: 0,
              trending: 0,
            },
            engagement: {
              emailOpen: 0,
              trending: 0,
              clickRate: 0,
            },
          },
          // Map isFollowed to isPriority if priority isn't present
          isPriority: campaign.priority || campaign.isFollowed || false,
          // Keep existing fields that match
          name: campaign.name,
          status: campaign.status,
          dateRange: campaign.dateRange,
          owner: campaign.owner,
          trend: campaign.trend,
        };
      });

      const uniqueCampaigns = Object.values(campaignsById);
      const sortedCampaigns = uniqueCampaigns.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date),
      );

      const campaignsList = [
        { campaign_id: "all", name: "All Campaigns" },
        ...sortedCampaigns,
      ];

      setCampaigns(campaignsList);
      setTotalCampaigns(sortedCampaigns.length);
    } catch (err) {
      console.error("Error fetching team campaigns:", err);
    }
  };

  const getMemberName = (userId) => {
    const member = teamMembers.find((member) => member.user_id === userId);
    return member ? member.username : "Unknown User";
  };

  const teamName = localStorage.getItem("team") || "Team Hub";

  const priorityCampaigns = campaigns.filter(
    (campaign) =>
      campaign.campaign_id !== "all" && // Exclude the "All Campaigns" option
      campaign.priority === true &&
      (campaign.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign.owner?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <AlertDialog open={showProfileModal} onOpenChange={setShowProfileModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Complete Your Profile</AlertDialogTitle>
            <AlertDialogDescription>
              To use the Team Hub, you need to complete your profile setup by
              adding your team and division information.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => history.push("/user-settings")}
              className="inline-flex items-center"
            >
              <UserCog className="mr-2 h-4 w-4" />
              Go to Settings
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Header */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <h1 className="text-xl font-semibold text-gray-900">
                {teamName} Hub
              </h1>
              <div className="flex space-x-4">
                {["Overview", "Campaigns", "Leads"].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => {
                      setActiveTab(tab.toLowerCase());
                      history.push(
                        tab.toLowerCase() === "overview"
                          ? "/team-hub"
                          : `/team-hub/${tab.toLowerCase() === "leads" ? "team-leads" : tab.toLowerCase()}`,
                      );
                    }}
                    className={`px-3 py-2 text-sm font-medium ${
                      location.pathname === `/team-hub/${tab.toLowerCase()}` ||
                      (location.pathname === "/team-hub/team-leads" &&
                        tab === "Leads") ||
                      (location.pathname === "/team-hub" &&
                        tab.toLowerCase() === "overview")
                        ? "text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content with Routes */}
      <Switch>
        <Route exact path="/team-hub">
          <div className="max-w-[1440px] mx-auto px-8 py-8 pb-20">
            <div className="flex gap-10">
              {/* Left Sidebar */}
              <div className="w-72 space-y-6">
                {/* Team Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Team Members</h2>
                      <span className="text-xs text-gray-500">
                        {teamMembers.length} members
                      </span>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-4">
                      {teamMembers.map((member) => (
                        <div
                          key={member.user_id}
                          className="flex items-center space-x-3"
                        >
                          <div className="relative">
                            <img
                              src={member.avatar}
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                            <div
                              className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-full border-2 border-white ${
                                member.status === "Available"
                                  ? "bg-green-500"
                                  : member.status === "Away"
                                    ? "bg-yellow-500"
                                    : member.status === "Busy"
                                      ? "bg-red-500"
                                      : "bg-gray-300"
                              }`}
                            />
                          </div>
                          <div className="flex-1">
                            <p className="text-sm font-medium">
                              {member.username}
                            </p>
                            <p className="text-xs text-gray-500">
                              {member.role}
                            </p>
                          </div>
                          <span className="text-xs text-gray-500">
                            {member.leads} leads • {member.uploads} uploads
                          </span>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>

                {/* Campaigns Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <h2 className="text-sm font-medium">Active Campaigns</h2>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-2">
                      {campaigns.map((campaign) => (
                        <button
                          key={campaign.campaign_id}
                          onClick={() =>
                            setActiveCampaign(campaign.campaign_id)
                          }
                          className={`w-full text-left px-3 py-2 rounded-md text-sm ${
                            activeCampaign === campaign.campaign_id
                              ? "bg-blue-50 text-blue-700"
                              : "text-gray-700 hover:bg-gray-50"
                          }`}
                        >
                          {campaign.name}
                        </button>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Main Content Area */}
              <div className="flex-1 min-w-0">
                {/* Metrics Overview */}
                <div className="grid grid-cols-3 gap-6 mb-8">
                  <MetricCard
                    label="Total Leads"
                    value={totalLeads}
                    trending={12.5}
                    icon={Users}
                  />
                  <MetricCard
                    label="Total Uploads"
                    value={totalUploads}
                    trending={5}
                    icon={FileUp}
                  />
                  <MetricCard
                    label="Active Campaigns"
                    value={totalCampaigns}
                    trending={1}
                    icon={FileSpreadsheet}
                  />
                </div>

                {/* Priority Campaigns Section */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-medium">
                        Priority Campaigns
                      </h2>
                      <button
                        onClick={() => history.push("/team-hub/campaigns")}
                        className="text-sm text-blue-600 hover:text-blue-700"
                      >
                        View All Campaigns
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="p-6">
                    <div className="space-y-6">
                      {priorityCampaigns.map((campaign) => (
                        <CampaignCard
                          key={campaign.campaign_id}
                          campaign={campaign}
                          isExpanded={expandedCampaign === campaign.campaign_id}
                          onToggleExpand={setExpandedCampaign}
                        />
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Right Sidebar */}
              <div className="w-96 space-y-6">
                {/* Calendar Card */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Upcoming</h2>
                      <button
                        onClick={() => setIsCalendarOpen(true)}
                        className="text-sm text-blue-600 hover:text-blue-700"
                      >
                        View Calendar
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <UpcomingEventsPreview
                      teamMembers={teamMembers}
                      auth={auth}
                      refreshTrigger={eventsRefreshTrigger}
                    />
                  </CardContent>
                </Card>

                <TeamCalendarModal
                  isOpen={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  teamMembers={teamMembers}
                  onEventAdded={() =>
                    setEventsRefreshTrigger((prev) => prev + 1)
                  }
                />

                {/* Recent Files */}
                <Card>
                  <CardHeader className="px-6 pb-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-medium">Recent Files</h2>
                      <button className="text-sm text-blue-600 hover:text-blue-700">
                        View All
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="px-6">
                    <div className="space-y-4">
                      {recentUploads.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3"
                        >
                          <div className="w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center">
                            <FileUp className="w-5 h-5 text-gray-600" />
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {file.file_name}
                            </p>
                            <p className="text-xs text-gray-500">
                              Added by {getMemberName(file.created_by)} •{" "}
                              {new Date(file.upload_date).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/team-hub/campaigns">
          <TeamCampaigns />
        </Route>
        <Route path="/team-hub/team-leads">
          <TeamLeads teamMembers={teamMembers} />
        </Route>
      </Switch>
    </div>
  );
};

export default TeamHub;
