import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  Link,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  PermIdentityTwoTone as PermIdentityIcon,
  BusinessTwoTone as BusinessIcon,
} from "@mui/icons-material";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import SuccessModal from "../../shared/components/UIElements/SuccessModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import image from "./leadfeed_login.png";

const Login = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    company: "",
    email: "",
    password: "",
  });
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    name: "",
    company: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedEmail && savedPassword) {
      setFormState((prevState) => ({
        ...prevState,
        email: savedEmail,
        password: savedPassword,
      }));
      setRememberMe(true);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { id, value, checked } = e.target;
    if (id === "rememberMe") {
      setRememberMe(checked);
    } else {
      setFormState({
        ...formState,
        [id]: value,
      });
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formState.name) errors.name = "Please enter your name.";
    if (!formState.company) errors.company = "Please enter your company name.";
    if (!formState.email) errors.email = "Please enter a valid email address.";
    if (!formState.password)
      errors.password =
        "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.";

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        await sendRequest(
          process.env.REACT_APP_API_URL + "/users/signup",
          "POST",
          JSON.stringify({
            email: formState.email,
            password: formState.password,
            username: formState.name,
            company: formState.company,
          }),
          { "Content-Type": "application/json" },
        );
        setSuccessMessage(
          "Your signup has been submitted. You will be emailed once your account has been activated.",
        );
      } catch (err) {
        // Error handling is managed by ErrorModal and useHttpClient
      }
    }
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formState.email) errors.email = "Please enter a valid email address.";
    if (!formState.password)
      errors.password =
        "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.";

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + "/users/login",
          "POST",
          JSON.stringify({
            email: formState.email,
            password: formState.password,
          }),
          { "Content-Type": "application/json" },
        );
        auth.login(
        responseData.user_id,
        responseData.token,
        responseData.role,
        responseData.username,
        responseData.team,
        responseData.division,
        responseData.time_zone
      );

      if (rememberMe) {
        localStorage.setItem("rememberedEmail", formState.email);
        localStorage.setItem("rememberedPassword", formState.password);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }

      history.push("/landing-page");
    } catch (err) {
      // Error handling is managed by ErrorModal and useHttpClient
    }
  }
};

  const handleForgotPasswordOpen = () => {
    setIsForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setIsForgotPasswordOpen(false);
  };

  const forgotPasswordHandler = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/password-reset-request",
        "POST",
        JSON.stringify({ email: forgotPasswordEmail }),
        { "Content-Type": "application/json" },
      );
      setSuccessMessage(
        "Please check your email for password reset instructions.",
      );
      setIsForgotPasswordOpen(false);
    } catch (err) {
      // Error handling is managed by ErrorModal and useHttpClient
    }
  };

  const forgotPasswordInputHandler = (event) => {
    setForgotPasswordEmail(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (isSignUp) {
        handleSignUpSubmit(e);
      } else {
        handleSignInSubmit(e);
      }
    }
  };

  return (
    <div id="root">
      <Container
        maxWidth="md"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.404)",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            minHeight: "50vh",
          }}
        >
          <Grid container sx={{ flexGrow: 1 }}>
            {isSignUp ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                  }}
                >
                  <img
                    src={image}
                    alt="leadfeed"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Create Account
                  </Typography>
                  <TextField
                    fullWidth
                    label="Your Name"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    size="small"
                    id="name"
                    value={formState.name}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Your Company"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    size="small"
                    id="company"
                    value={formState.company}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    size="small"
                    id="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    id="password"
                    value={formState.password}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginBottom: 3 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSignUpSubmit}
                  >
                    Sign Up
                  </Button>
                  <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                    <Typography variant="body2">
                      Already have an account?{" "}
                      <Link href="#" onClick={() => setIsSignUp(false)}>
                        Sign In
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Sign In
                  </Typography>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    size="small"
                    id="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    id="password"
                    value={formState.password}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid item>
                      <Checkbox
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={handleInputChange}
                      />
                      <Typography variant="body2" component="span">
                        Remember me
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link
                        href="#"
                        variant="body2"
                        onClick={handleForgotPasswordOpen}
                      >
                        Forgot Password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSignInSubmit}
                  >
                    Log in
                  </Button>
                  <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                    <Typography variant="body2">
                      Don't have an account?{" "}
                      <Link href="#" onClick={() => setIsSignUp(true)}>
                        Create an account
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                  }}
                >
                  <img
                    src={image}
                    alt="leadfeed"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Container>

      <Dialog open={isForgotPasswordOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="forgotPasswordEmail"
            label="Email Address"
            type="email"
            fullWidth
            value={forgotPasswordEmail}
            onChange={forgotPasswordInputHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose} color="primary">
            Cancel
          </Button>
          <Button onClick={forgotPasswordHandler} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorModal error={error} onClear={clearError} />
      {successMessage && (
        <SuccessModal
          success={successMessage}
          onClear={() => setSuccessMessage(null)}
        />
      )}
      {isLoading && <LoadingSpinner asOverlay />}
    </div>
  );
};

export default Login;
