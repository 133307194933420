import React, { useContext, useEffect, useState, useMemo } from "react";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  isToday,
  isYesterday,
  startOfISOWeek,
  startOfDay,
  isAfter,
  isSameDay,
  parseISO,
} from "date-fns";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Settings, LogOut, Circle, Check } from "lucide-react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppsIcon from "@mui/icons-material/Apps";
import MailIcon from "@mui/icons-material/Mail";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpIcon from "@mui/icons-material/Help";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { AuthContext } from "../../context/auth-context";
import { NotificationContext } from "../../context/NotificationContext";
import defaultUserImage from "../../../user/components/user.png";
import logo from "../../../../src/CBLogo.png";

const StyledBadge = styled(Badge)(({ theme, badgeColor, status }) => ({
  position: "relative",
  display: "inline-flex",
  "& .MuiBadge-badge": {
    backgroundColor: badgeColor,
    color: badgeColor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    ...(status === "Available" && {
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    }),
  },
  ...(status === "Available" && {
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }),
}));

const NotificationsMenu = ({
  anchorEl,
  open,
  onClose,
  notifications,
  onMarkAsRead,
  onMarkAllAsRead,
  auth
}) => {
  useEffect(() => {
    const userTimeZone = localStorage.getItem("time_zone");
  }, []);

  const handleDownload = async (fileId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/downloads/${fileId}`, {
      headers: {
        Authorization: `Bearer ${auth.token}`
      }
    });
    const data = await response.json();
    window.location.href = data.url;
  } catch (error) {
    console.error('Download error:', error);
  }
};

  const getNotificationAction = (notification) => {
    if (
      notification.type === "Download Ready" &&
      notification.metadata?.file_id
    ) {
      return (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(notification.metadata.file_id);
          }}
          sx={{ mr: 1 }}
        >
          <GetAppIcon sx={{ fontSize: 20, color: "#2196f3" }} />
        </IconButton>
      );
    }
    return null;
  };

  const formatDate = (dateString) => {
    try {
      const userTimeZone =
        localStorage.getItem("time_zone") || "America/New_York";

      // Convert the date string to ISO format
      const [datePart, timePart] = dateString.split(" ");
      const isoString = `${datePart}T${timePart}Z`;

      // Parse and convert to user's timezone
      const utcDate = parseISO(isoString);
      const zonedDate = utcToZonedTime(utcDate, userTimeZone);

      // Format based on whether it's today or not
      if (isToday(zonedDate)) {
        return format(zonedDate, "h:mm a", { timeZone: userTimeZone });
      }

      return format(zonedDate, "MMM d, h:mm a", { timeZone: userTimeZone });
    } catch (error) {
      console.error("Error formatting date:", error, dateString);
      return "Invalid Date";
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "Download Ready":
        return <GetAppIcon sx={{ color: "#2196f3" }} />;
      case "New Feature":
        return <NewReleasesIcon sx={{ color: "#4caf50" }} />;
      case "General":
        return <AnnouncementIcon sx={{ color: "#ff9800" }} />;
      case "New User Signup":
        return <PersonIcon sx={{ color: "#1976d2" }} />;
      default:
        return <NotificationsIcon sx={{ color: "#757575" }} />;
    }
  };

  const groupedNotifications = useMemo(() => {
    const userTimeZone =
      localStorage.getItem("time_zone") || "America/New_York";

    const groups = {
      today: [],
      yesterday: [],
      thisWeek: [],
      older: [],
    };

    const now = new Date();
    const zonedNow = utcToZonedTime(now, userTimeZone);

    notifications.forEach((notification) => {
      try {
        const [datePart, timePart] = notification.date.split(" ");
        const isoString = `${datePart}T${timePart}Z`;
        const utcDate = parseISO(isoString);
        const zonedDate = utcToZonedTime(utcDate, userTimeZone);

        const startOfWeek = startOfISOWeek(zonedNow);
        const notificationDate = startOfDay(zonedDate);

        if (isToday(zonedDate)) {
          groups.today.push(notification);
        } else if (isYesterday(zonedDate)) {
          groups.yesterday.push(notification);
        } else if (
          isAfter(notificationDate, startOfWeek) ||
          isSameDay(notificationDate, startOfWeek)
        ) {
          groups.thisWeek.push(notification);
        } else {
          groups.older.push(notification);
        }
      } catch (error) {
        console.error(
          "Error processing notification date:",
          error,
          notification,
        );
        groups.older.push(notification);
      }
    });

    const sortByDate = (a, b) => new Date(b.date) - new Date(a.date);
    groups.today.sort(sortByDate);
    groups.yesterday.sort(sortByDate);
    groups.thisWeek.sort(sortByDate);
    groups.older.sort(sortByDate);

    return groups;
  }, [notifications]);

  const renderNotificationGroup = (notifications, groupTitle) => {
    if (notifications.length === 0) return null;

    return (
      <>
        <ListSubheader
          sx={{
            bgcolor: "grey.50",
            py: 1,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 56,
            zIndex: 1,
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {groupTitle}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {notifications.length}{" "}
            {notifications.length === 1 ? "notification" : "notifications"}
          </Typography>
        </ListSubheader>
        {notifications.map((notification) => (
          <MenuItem
            key={notification.notification_id}
            sx={{
              px: 2,
              py: 1.5,
              "&:hover": { bgcolor: "grey.50" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ mt: 0.5 }}>
                {getNotificationIcon(notification.type)}
              </Box>

              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: 1,
                    minWidth: 0,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                    {notification.type}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                  >
                    {formatDate(notification.date)}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 0.5,
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {notification.message}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {getNotificationAction(notification)}
                <Tooltip title="Mark as read">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onMarkAsRead(notification.notification_id);
                    }}
                    sx={{ "&:hover": { bgcolor: "grey.100" } }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 380,
          maxHeight: "80vh",
          overflowY: "auto",
          mt: 1,
          borderRadius: 2,
          boxShadow: 3,
          "& .MuiList-root": {
            padding: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 2,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Notifications
          </Typography>
          <Tooltip title="Mark all as read">
            <IconButton
              size="small"
              onClick={onMarkAllAsRead}
              sx={{ "&:hover": { bgcolor: "grey.100" } }}
            >
              <MailIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {notifications.length === 0 ? (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography color="text.secondary">No new notifications</Typography>
        </Box>
      ) : (
        <Box>
          {renderNotificationGroup(groupedNotifications.today, "Today")}
          {renderNotificationGroup(groupedNotifications.yesterday, "Yesterday")}
          {renderNotificationGroup(groupedNotifications.thisWeek, "This Week")}
          {renderNotificationGroup(groupedNotifications.older, "Older")}
        </Box>
      )}
    </Menu>
  );
};

const Header = () => {
  const auth = useContext(AuthContext);
  const { notifications, markAsRead, markAllAsRead } =
    useContext(NotificationContext);
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileImage, setProfileImage] = useState(defaultUserImage);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [lastActivity, setLastActivity] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);
  const [manualStatus, setManualStatus] = useState("Available");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (auth.isAuthLoaded && !auth.isLoggedIn) {
      history.push("/");
    }
  }, [auth.isAuthLoaded, auth.isLoggedIn, history]);

  useEffect(() => {
    if (auth.isAuthReady && auth.user_id) {
      const fetchProfileImage = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/profile-image`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          );
          if (!response.ok) {
            throw new Error("Failed to fetch profile image");
          }
          const data = await response.json();
          setProfileImage(data.image_url || defaultUserImage);
        } catch (error) {
          console.error("Error fetching profile image:", error);
        }
      };

      fetchProfileImage();
    }
  }, [auth.isAuthReady, auth.user_id, auth.token]);

  useEffect(() => {
    switch (location.pathname) {
      case "/landing-page":
        setPageTitle("");
        break;
      case "/leads/new":
        setPageTitle("Create Lead");
        break;
      case "/leads/uploadlist":
        setPageTitle("Upload List");
        break;
      case `/${auth.user_id}/leads`:
        setPageTitle("My Leads");
        break;
      case `/${auth.user_id}/uploads`:
        setPageTitle("My Uploads");
        break;
      case "/leadflow":
        setPageTitle("Lead Flow");
        break;
      case "/accounts/management":
        setPageTitle("Account Management");
        break;
      case "/zip-code-management":
        setPageTitle("Zip Code Routing");
        break;
      case "/queue-mapping":
        setPageTitle("CRM Queue Mapping");
        break;
      case "/api-integration":
        setPageTitle("API Integration");
        break;
      case "/all-users":
        setPageTitle("All Users");
        break;
      case "/user-settings":
        setPageTitle("User Settings");
        break;
      case "/campaigns/track":
        setPageTitle("Campaign Track");
        break;
      case "/audience-builder":
        setPageTitle("Audience Builder");
        break;
      case "/team-hub":
        setPageTitle("My Team");
        break;
      case "/team-hub/campaigns":
        setPageTitle("My Team - Campaigns");
        break;
      case "/team-hub/team-leads":
        setPageTitle("My Team - Leads");
        break;
      default:
        setPageTitle("Dashboard");
        break;
    }
  }, [location.pathname, auth.user_id]);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${auth.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user status");
        }
        const data = await response.json();
        setLastActivity(data.last_activity);
        setTokenExpiry(data.token_expiry);
        setManualStatus(data.manual_status || "Available");
      } catch (error) {
        console.error("Error fetching user status:", error);
      }
    };

    if (auth.user_id && auth.token) {
      fetchUserStatus();
    }
  }, [auth.user_id, auth.token]);

  const handleStatusChange = async (newStatus) => {
    try {
      setManualStatus(newStatus);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/status`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStatus),
        },
      );
      if (!response.ok) throw new Error("Failed to update status");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const currentTime = new Date();
  let status = "Offline";
  if (tokenExpiry && new Date(tokenExpiry) > currentTime) {
    if (lastActivity) {
      const lastActivityTime = new Date(lastActivity);
      const diffMs = currentTime - lastActivityTime;
      const diffMins = diffMs / (1000 * 60);
      if (diffMins < 15) {
        status = "Available";
      } else {
        status = "Away";
      }
    } else {
      status = "Away";
    }
  }

  const statusColors = {
    Available: "#44b700",
    Busy: "#ff0000",
    Away: "#fbc02d",
    Offline: "#6e6e6e",
  };

  const combinedStatus = manualStatus || status;

  const badgeColor = statusColors[combinedStatus];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePagesMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleContactMenu = (event) => {
    setContactAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationsAnchorEl(null);
    setMenuAnchorEl(null);
    setContactAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    history.push("/");
    handleClose();
  };

  const handleUserSettings = () => {
    history.push("/user-settings");
    handleClose();
  };

  const handleContactUs = () => {
    window.location.href =
      "mailto:leadfeedteam@comcast.com?subject=leadfeed%20Feedback";
    handleClose();
  };

  const isActive = (path) => currentPath === path;

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "#1976d2", height: "68px", boxShadow: "none" }}
    >
      <Toolbar sx={{ minHeight: "60px", position: "relative" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/landing-page"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img
              src={logo}
              alt="CB Logo"
              style={{ height: "40px", marginRight: "10px" }}
            />
          </Link>
        </Box>
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            fontSize: "1.4rem",
            fontWeight: "bold",
            display: isMobile ? "none" : "block",
          }}
        >
          {pageTitle}
        </Typography>

        {/* Right-aligned: User Actions */}
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
        >
          {/* Pages Menu */}
          <Tooltip title="Pages">
            <IconButton
              edge="start"
              aria-label="apps"
              aria-controls="menu-pages"
              aria-haspopup="true"
              onClick={handlePagesMenu}
              color="inherit"
              sx={{ fontSize: "1.5rem" }}
            >
              <AppsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-pages"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
            sx={{ marginTop: "5px" }}
          >
            {auth.isLoggedIn && (
              <>
                <ListSubheader>Quick Actions</ListSubheader>
                <MenuItem
                  component={NavLink}
                  to="/leads/new"
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/leads/new")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Create Lead" />
                  {isActive("/leads/new") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/leads/uploadlist"
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/leads/uploadlist")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Upload List" />
                  {isActive("/leads/uploadlist") && <CheckIcon />}
                </MenuItem>
                <ListSubheader>My Activity</ListSubheader>
                <MenuItem
                  component={NavLink}
                  to={`/${auth.user_id}/leads`}
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive(`/${auth.user_id}/leads`)
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="My Leads" />
                  {isActive(`/${auth.user_id}/leads`) && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`/${auth.user_id}/uploads`}
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive(`/${auth.user_id}/uploads`)
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="My Uploads" />
                  {isActive(`/${auth.user_id}/uploads`) && <CheckIcon />}
                </MenuItem>
              </>
            )}
            {auth.role === "admin" && (
              <>
                <ListSubheader>Administration</ListSubheader>
                <MenuItem
                  component={NavLink}
                  to="/leadflow"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/leadflow")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Lead Flow" />
                  {isActive("/leadflow") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/accounts/management"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/accounts/management")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Account Management" />
                  {isActive("/accounts/management") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/zip-code-management"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/zip-code-management")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Zip Code Routing" />
                  {isActive("/zip-code-management") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/queue-mapping"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/queue-mapping")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="CRM Queue Mapping" />
                  {isActive("/queue-mapping") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/api-integration"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/api-integration")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="API Integration" />
                  {isActive("/api-integration") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/all-users"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/all-users")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="All Users" />
                  {isActive("/all-users") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/campaigns/track"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/campaigns/track")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Campaign Track" />
                  {isActive("/campaigns/track") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/audience-builder"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/audience-builder")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="Audience Builder" />
                  {isActive("/audience-builder") && <CheckIcon />}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/team-hub"
                  exact
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: isActive("/team-hub")
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                  }}
                >
                  <ListItemText primary="My Team" />
                  {isActive("/team-hub") && <CheckIcon />}
                </MenuItem>
              </>
            )}
          </Menu>
          {/* Support Menu */}
          <Tooltip title="Support">
            <IconButton
              edge="start"
              aria-label="contact support"
              aria-controls="menu-contact"
              aria-haspopup="true"
              onClick={handleContactMenu}
              color="inherit"
              sx={{ fontSize: "1.5rem" }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-contact"
            anchorEl={contactAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(contactAnchorEl)}
            onClose={handleClose}
            sx={{ marginTop: "5px" }}
          >
            <MenuItem onClick={handleContactUs}>
              <ListItemText primary="Contact Us" />
            </MenuItem>
          </Menu>
          {/* Notifications */}
          <Badge
            badgeContent={notifications.length}
            color="error"
            sx={{ padding: 0 }}
          >
            <IconButton
              edge="end"
              aria-label="notifications"
              color="inherit"
              onClick={(event) => setNotificationsAnchorEl(event.currentTarget)}
              sx={{ fontSize: ".8rem" }}
            >
              <NotificationsIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Badge>
          <NotificationsMenu
            anchorEl={notificationsAnchorEl}
            open={Boolean(notificationsAnchorEl)}
            onClose={() => setNotificationsAnchorEl(null)}
            notifications={notifications}
            onMarkAsRead={markAsRead}
            onMarkAllAsRead={() => {
              markAllAsRead();
              setNotificationsAnchorEl(null);
            }}
            auth={auth}
          />
          {/* User Menu */}
          <Box sx={{ position: "relative", ml: 4 }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ fontSize: "1.5rem" }}
            >
              <Tooltip title={combinedStatus}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  badgeColor={badgeColor}
                  status={status}
                >
                  <Avatar
                    src={profileImage}
                    sx={{
                      width: 45,
                      height: 45,
                    }}
                  />
                </StyledBadge>
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 3,
            sx: {
              mt: 1.5,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
              "& .MuiMenuItem-root": {
                px: 2,
                py: 1.5,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              },
              "& .MuiDivider-root": {
                my: 1,
              },
            },
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 1,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "rgb(37, 47, 63)",
              }}
            >
              Status
            </Typography>
          </Box>
          <MenuItem
            onClick={() => handleStatusChange("Available")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Available}
              color={statusColors.Available}
              size={12}
            />
            <ListItemText primary="Available" />
            {manualStatus === "Available" && (
              <Check size={18} className="ml-auto" />
            )}
          </MenuItem>

          <MenuItem
            onClick={() => handleStatusChange("Busy")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Busy}
              color={statusColors.Busy}
              size={12}
            />
            <ListItemText primary="Busy" />
            {manualStatus === "Busy" && <Check size={18} className="ml-auto" />}
          </MenuItem>

          <MenuItem
            onClick={() => handleStatusChange("Away")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Away}
              color={statusColors.Away}
              size={12}
            />
            <ListItemText primary="Appear Away" />
            {manualStatus === "Away" && <Check size={18} className="ml-auto" />}
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={handleUserSettings}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Settings size={18} />
            <ListItemText primary="User Settings" />
          </MenuItem>

          <MenuItem
            onClick={handleLogout}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <LogOut size={18} />
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
