import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import Users from "./user/pages/Users";
import NewLead from "./leads/pages/NewLead";
import UploadList from "./leads/pages/UploadList";
import APIIntegration from "./user/pages/APIIntegration";
import LandingPage from "./user/landingPage/LandingPage";
import UserLeads from "./leads/pages/UserLeads";
import AccountManagement from "./accounts/pages/AccountManagement";
import UserUploads from "./uploads/pages/UserUploads";
import ReportingDashboard from "./reporting/ReportingDashboard";
import QueueMapping from "./queue_mapping/pages/QueueMapping";
import Login from "./user/pages/Login";
import ResetPassword from "./user/pages/ResetPassword";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import UserSettings from "./user/pages/UserSettings";
import ZipCodeManagement from "./zip_codes/pages/ZipCodeManagement";
import Campaigns from "./campaigns/pages/Campaigns";
import AudienceBuilder from "./leads/pages/AudienceBuilder";
import TeamHub from "./teamhub/pages/TeamHub";
import TeamCampaigns from "./teamhub/components/TeamCampaigns";
import PrivateRoute from "./PrivateRoute";
import {
  AuthContextProvider,
  AuthContext,
} from "./shared/context/auth-context";
import { NotificationProvider } from "./shared/context/NotificationContext";
import TokenExpirationHandler from './shared/components/TokenExpirationHandler';
import { CssBaseline, Box } from "@mui/material";
import "./App.css";

const App = () => {
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const pathsWithoutNav = ["/landing-page"];

  return (
    <>
      {!pathsWithoutNav.includes(location.pathname) && token && (
        <MainNavigation />
      )}
      <CssBaseline />
      <Box className="main-content">
        <Box className="scrollable-container">
          <Switch>
            <Route path="/reset-password" component={ResetPassword} />
            {token ? (
              <>
                <Route path="/all-users" exact component={Users} />
                <Route path="/:user_id/uploads" exact component={UserUploads} />
                <Route path="/:user_id/leads" exact component={UserLeads} />
                <Route path="/landing-page" exact component={LandingPage} />
                <Route path="/leads/new" exact component={NewLead} />
                <Route path="/leads/uploadlist" exact component={UploadList} />
                <Route path="/user-settings" exact component={UserSettings} />
                <PrivateRoute path="/accounts/management" exact component={AccountManagement} />
                <PrivateRoute path="/zip-code-management" exact component={ZipCodeManagement} />
                <PrivateRoute path="/queue-mapping" exact component={QueueMapping} />
                <PrivateRoute path="/api-integration" exact component={APIIntegration} />
                <PrivateRoute path="/leadflow" exact component={ReportingDashboard} />
                <PrivateRoute path="/audience-builder" exact component={AudienceBuilder} />
                <PrivateRoute  path="/campaigns/track" exact component={Campaigns} />
                <PrivateRoute  path="/team-hub/:tab?" component={TeamHub} />
                <Redirect to="/landing-page" />
              </>
            ) : (
              <>
                <Route path="/" exact component={Login} />
                <Route path="/auth" component={Login} />
                <Redirect to="/auth" />
              </>
            )}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <AuthContextProvider>
      <NotificationProvider> 
        <TokenExpirationHandler />
          <App />
      </NotificationProvider>
    </AuthContextProvider>
  </Router>
);

export default AppWithRouter;
