import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Portal from "@mui/material/Portal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import "./AccountManagement.css";
import { AuthContext } from "../../shared/context/auth-context";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff",
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold",
        },
        columnHeaderTitle: {
          fontSize: "16px",
        },
        cell: {
          borderBottom: "1px solid #ddd",
        },
        row: {
          "&:hover": {
            backgroundColor: "#e3f2fd",
          },
        },
        footerContainer: {
          backgroundColor: "#f1f3f4",
        },
      },
    },
  },
});

// Function to calculate the width of a column based on its header name length
const calculateColumnWidth = (headerName) => {
  const baseWidth = 50; // base width for padding and margins
  const charWidth = 10; // approximate width of each character
  return baseWidth + headerName.length * charWidth;
};

// Custom Pagination Component
const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(
    apiRef,
    (state) => state.pagination.pageCount,
  );

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

// Custom Toolbar Component
const CustomToolbar = (props) => {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById("filter-panel")}>
        <GridToolbarQuickFilter />
      </Portal>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </React.Fragment>
  );
};

const AccountManagement = () => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("account_management", auth);
    }
  }, [auth]);

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/accounts/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setAccounts(responseData);
      } catch (err) {}
      setIsLoading(false);
    };
    fetchAccounts();
  }, [sendRequest]);

  const handleEditCellChange = useCallback(({ id, field, value }) => {
    setAccounts((prev) =>
      prev.map((row) =>
        row.account_id === id ? { ...row, [field]: value } : row,
      ),
    );
  }, []);

  const handleCellEditCommit = useCallback(
    async ({ id, field, value }) => {
      try {
        const updatedAccount = { [field]: value };
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/accounts/${id}`,
          "PATCH",
          JSON.stringify(updatedAccount),
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        );
      } catch (err) {}
    },
    [sendRequest, auth.token],
  );

  const deleteAccountHandler = async (id) => {
    const confirmation = window.prompt("Type 'delete' to confirm:");
    if (confirmation === "delete") {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/accounts/${id}`,
          "DELETE",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setAccounts((prevAccounts) =>
          prevAccounts.filter((acc) => acc.account_id !== id),
        );
      } catch (err) {}
    }
  };

  // Predefined options for Named Account Type
  const namedAccountTypeOptions = [
    "CB GLOBAL NAMED ACCOUNT",
    "CUSTOM ENTERPRISE NAMED ACCOUNT",
    "FEDERAL GOVERNMENT NAMED AGENCY",
    "FIELD ENTERPRISE NAMED ACCOUNT",
    "HOSPITALITY NAMED ACCOUNT",
    "SALESFORCE MODULED ACCOUNT",
    "DYNAMICS MODULED ACCOUNT",
  ];

  // Custom Edit component for Named Account Type field
  const renderEditNamedAccountType = (params) => {
    return (
      <Select
        value={params.value}
        onChange={(event) => {
          params.api.setEditCellValue(
            { id: params.id, field: params.field, value: event.target.value },
            event,
          );
          handleCellEditCommit({
            id: params.id,
            field: params.field,
            value: event.target.value,
          });
        }}
        autoFocus
      >
        {namedAccountTypeOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      field: "named_account_type",
      headerName: "Named Account Type",
      width: 350,
      editable: true,
      renderEditCell: renderEditNamedAccountType,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      width: 300,
      editable: true,
    },
    { field: "website", headerName: "Website", width: 200, editable: true },
    { field: "websites", headerName: "Websites", width: 200, editable: true },
    {
      field: "named_subsidiaries",
      headerName: "Named Subsidiaries",
      width: calculateColumnWidth("Named Subsidiaries"),
      editable: true,
    },
    {
      field: "account_executive_director",
      headerName: "Account Executive Director",
      width: calculateColumnWidth("Account Executive Director"),
      editable: true,
    },
    {
      field: "account_director",
      headerName: "Account Director",
      width: calculateColumnWidth("Account Director"),
      editable: true,
    },
    {
      field: "account_director_email",
      headerName: "Account Director Email",
      width: calculateColumnWidth("Account Director Email"),
      editable: true,
    },
    {
      field: "account_director_id_salesforce",
      headerName: "Account Director ID Salesforce",
      width: calculateColumnWidth("Account Director ID Salesforce"),
      editable: true,
    },
    {
      field: "account_director_id_dynamics",
      headerName: "Account Director ID Dynamics",
      width: calculateColumnWidth("Account Director ID Dynamics"),
      editable: true,
    },
    {
      field: "account_owner",
      headerName: "Account Owner",
      width: calculateColumnWidth("Account Owner"),
      editable: true,
    },
    {
      field: "account_owner_email",
      headerName: "Account Owner Email",
      width: calculateColumnWidth("Account Owner Email"),
      editable: true,
    },
    {
      field: "owner_division",
      headerName: "Owner Division",
      width: calculateColumnWidth("Owner Division"),
      editable: true,
    },
    {
      field: "account_owner_id_salesforce",
      headerName: "Account Owner ID Salesforce",
      width: calculateColumnWidth("Account Owner ID Salesforce"),
      editable: true,
    },
    {
      field: "account_owner_id_dynamics",
      headerName: "Account Owner ID Dynamics",
      width: calculateColumnWidth("Account Owner ID Dynamics"),
      editable: true,
    },
    {
      field: "am_module_sfdc",
      headerName: "AM Module SFDC",
      width: calculateColumnWidth("AM Module SFDC"),
      editable: true,
    },
    {
      field: "am_module_dynamics",
      headerName: "AM Module Dynamics",
      width: calculateColumnWidth("AM Module Dynamics"),
      editable: true,
    },
    {
      field: "business_hq_ultimate_id",
      headerName: "Business HQ Ultimate ID",
      width: calculateColumnWidth("Business HQ Ultimate ID"),
      editable: false,
    },
    {
      field: "business_hq_immediate_id",
      headerName: "Business HQ Immediate ID",
      width: calculateColumnWidth("Business HQ Immediate ID"),
      editable: false,
    },
    {
      field: "brand_id",
      headerName: "Brand ID",
      width: calculateColumnWidth("Brand ID"),
      editable: false,
    },
    {
      field: "ultimate_parent_name",
      headerName: "Ultimate Parent Name",
      width: calculateColumnWidth("Ultimate Parent Name"),
      editable: false,
    },
    {
      field: "immediate_parent_name",
      headerName: "Immediate Parent Name",
      width: calculateColumnWidth("Immediate Parent Name"),
      editable: false,
    },
    {
      field: "brand_name",
      headerName: "Brand Name",
      width: calculateColumnWidth("Brand Name"),
      editable: false,
    },
    {
      field: "business_email_domains",
      headerName: "Business Email Domains",
      width: calculateColumnWidth("Business Email Domains"),
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: calculateColumnWidth("Actions"),
      sortable: false,
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteAccountHandler(params.id)}
          />
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && accounts && (
        <ThemeProvider theme={theme}>
          <div className="account-management-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="filter-panel" />
              </Grid>
              <Grid item xs={12}>
                <div className="account-management-grid">
                  <DataGrid
                    rows={accounts}
                    columns={columns}
                    getRowId={(row) => row.account_id}
                    pageSize={100}
                    pagination
                    components={{
                      Toolbar: CustomToolbar,
                      Pagination: CustomPagination,
                    }}
                    disableSelectionOnClick
                    onEditCellChange={handleEditCellChange}
                    onCellEditCommit={handleCellEditCommit}
                    density="compact"
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterExcludeHiddenColumns: true,
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default AccountManagement;
