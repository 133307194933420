import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff, Lock as LockIcon } from '@mui/icons-material';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import image from './leadfeed_login.png';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const token = query.get('token');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => ({
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  });

  const getColor = (isValid) => {
    if (isValid) return 'green';
    if (!newPassword) return 'gray';
    return 'red';
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const passwordIsValid = Object.values(validatePassword(newPassword)).every(Boolean);

    if (!passwordIsValid || newPassword !== confirmPassword) {
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/reset-password`,
        'POST',
        JSON.stringify({ token, new_password: newPassword }),
        { 'Content-Type': 'application/json' }
      );
      history.push('/auth');
    } catch (err) {
      console.error(err);
    }
  };

  const passwordChecks = validatePassword(newPassword);

  return (
    <div id="root">
      <Container
        maxWidth="md"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.404)",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            minHeight: "50vh",
          }}
        >
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              <img
                src={image}
                alt="leadfeed"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "15px 0 0 15px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Reset Password
              </Typography>
              <form onSubmit={submitHandler}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    fullWidth
                    label="New Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    id="newPassword"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>
                    <Typography
                      sx={{
                        color: getColor(passwordChecks.length),
                      }}
                    >
                      ✓ Minimum 8 characters
                    </Typography>
                    <Typography
                      sx={{
                        color: getColor(passwordChecks.uppercase),
                      }}
                    >
                      ✓ One uppercase letter
                    </Typography>
                    <Typography
                      sx={{
                        color: getColor(passwordChecks.lowercase),
                      }}
                    >
                      ✓ One lowercase letter
                    </Typography>
                    <Typography
                      sx={{
                        color: getColor(passwordChecks.number),
                      }}
                    >
                      ✓ One number
                    </Typography>
                    <Typography
                      sx={{
                        color: getColor(passwordChecks.special),
                      }}
                    >
                      ✓ One special character
                    </Typography>
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={confirmPassword && confirmPassword !== newPassword}
                    helperText={
                      confirmPassword && confirmPassword !== newPassword
                        ? "The confirmed password does not match the new password you entered."
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={
                    Object.values(passwordChecks).some((check) => !check) ||
                    confirmPassword !== newPassword
                  }
                  sx={{ marginTop: 2 }}
                >
                  Reset Password
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
    </div>
  );
};

export default ResetPassword;
