import React, { useContext, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Box,
  Typography,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  Business,
  Person,
  ArrowForward,
  ArrowBack,
  CheckCircle,
} from "@mui/icons-material";
 
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ProductOptions } from "../components/ProductOptions";
import { StateList } from "../components/StateList";
import "./NewLead.css";
 
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};
 
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
 
const SuccessDialog = ({ open, onClose, message }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle sx={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}>
      Success
    </DialogTitle>
    <DialogContent sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <CheckCircle sx={{ color: "green", fontSize: 40, marginRight: 1 }} />
        <Typography variant="body1" sx={{ color: "green", fontWeight: "bold" }}>
          {message}
        </Typography>
      </Box>
      <Button onClick={onClose} variant="contained" color="primary">
        OK
      </Button>
    </DialogContent>
  </Dialog>
);
 
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
 
const NewLead = () => {
  const [value, setValue] = useState(0);
  const [products, setProducts] = useState([]);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMessage, setErrorMessage] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
 
  const [formState, inputHandler, setFormData] = useForm(
    {
      business_name: { value: "", isValid: false },
      address: { value: "", isValid: false },
      address2: { value: "", isValid: true },
      city: { value: "", isValid: false },
      state_or_province: { value: "", isValid: false },
      zip_or_postal_code: { value: "", isValid: false },
      website: { value: "", isValid: true },
      contact_first_name: { value: "", isValid: false },
      contact_last_name: { value: "", isValid: false },
      contact_job_title: { value: "", isValid: false },
      contact_phone: { value: "", isValid: false },
      contact_phone_extension: { value: "", isValid: true },
      contact_mobile: { value: "", isValid: true },
      contact_email: { value: "", isValid: false },
      number_of_employees: { value: "", isValid: true },
      us_federal_agency: { value: false, isValid: true },
    },
    false,
  );
 
  // Load saved form data from localStorage on component mount
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("leadFormData"));
    if (savedFormData) {
      setFormData(savedFormData, true); // Set the saved form data and mark it as valid
    }
  }, [setFormData]);
 
 
  const autosaveHandler = useCallback(
    debounce(() => {
      localStorage.setItem("leadFormData", JSON.stringify(formState.inputs));
    }, 500),
    [formState],
  );
 
  useEffect(() => {
    autosaveHandler();
  }, [formState, autosaveHandler]);
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const handleNext = () => {
    setValue((prevValue) => prevValue + 1);
  };
 
  const handleBack = () => {
    setValue((prevValue) => prevValue - 1);
  };
 
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    history.push("/");
  };
 
  const leadSubmitHandler = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      setErrorMessage("Please fill all required fields correctly.");
      return;
    }
    try {
      const productsString = products
        .map((product) => product.value)
        .sort()
        .join(", ");
      let leadData = {
        business_name: formState.inputs.business_name.value,
        address: formState.inputs.address.value,
        city: formState.inputs.city.value,
        state_or_province: formState.inputs.state_or_province.value,
        zip_or_postal_code: formState.inputs.zip_or_postal_code.value,
        website: formState.inputs.website.value.toLowerCase(),
        contact_first_name: formState.inputs.contact_first_name.value,
        contact_last_name: formState.inputs.contact_last_name.value,
        contact_job_title: formState.inputs.contact_job_title.value,
        contact_phone: formState.inputs.contact_phone.value,
        contact_email: formState.inputs.contact_email.value.toLowerCase(),
        products_of_interest: productsString,
        us_federal_agency: formState.inputs.us_federal_agency.value,
        contact_mobile: formState.inputs.contact_mobile.value,
        created_by: auth.user_id,
      };
      for (const key in leadData) {
        if (leadData[key] === "" || leadData[key] == null) {
          delete leadData[key];
        }
      }
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/leads/uploadform`,
        "POST",
        JSON.stringify(leadData),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
      setSuccessMessage("Lead created successfully!");
      setSuccessDialogOpen(true);
      // Clear localStorage after successful submission
      localStorage.removeItem("leadFormData");
    } catch (err) {
      setErrorMessage(
        "Failed to submit form. Please ensure all required fields are filled.",
      );
      console.error("Failed to submit form:", err);
    }
  };
 
  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      height: 56,
      padding: 0,
      "& input": {
        height: "100%",
        boxSizing: "border-box",
        padding: "0 14px",
        lineHeight: "normal",
      },
    },
  };
 
  return (
    <React.Fragment>
      <ErrorModal error={errorMessage} onClear={() => setErrorMessage("")} />
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
        message={successMessage}
      />
      <Container
        maxWidth="lg"
        sx={{
          minHeight: "calc(100vh - 20px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          mt: { xs: 0, sm: 4 },
          marginBottom: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: isSmallScreen ? "100%" : "80%",
            maxWidth: 800,
            p: 2,
          }}
        >
          <Box>
            {isLoading && <CircularProgress />}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lead form tabs"
                variant="fullWidth"
              >
                <Tab
                  icon={<Business />}
                  iconPosition="start"
                  label="Company Details"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<Person />}
                  iconPosition="start"
                  label="Contact Details"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <Box component="form" onSubmit={leadSubmitHandler} sx={{ mt: 1 }}>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="business_name"
                      name="business_name"
                      label="Company Name"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.business_name.value}
                      onChange={(e) =>
                        inputHandler(
                          "business_name",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.business_name.isValid &&
                        formState.inputs.business_name.value !== ""
                      }
                      helperText={
                        !formState.inputs.business_name.isValid &&
                        formState.inputs.business_name.value !== ""
                          ? "Company name is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="address"
                      name="address"
                      label="Address"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.address.value}
                      onChange={(e) =>
                        inputHandler(
                          "address",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.address.isValid &&
                        formState.inputs.address.value !== ""
                      }
                      helperText={
                        !formState.inputs.address.isValid &&
                        formState.inputs.address.value !== ""
                          ? "Address is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="address2"
                      name="address2"
                      label="Address 2 (optional)"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.address2.value}
                      onChange={(e) =>
                        inputHandler("address2", e.target.value, true)
                      }
                      className="form-field"
                      sx={inputStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="city"
                      name="city"
                      label="City"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.city.value}
                      onChange={(e) =>
                        inputHandler(
                          "city",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.city.isValid &&
                        formState.inputs.city.value !== ""
                      }
                      helperText={
                        !formState.inputs.city.isValid &&
                        formState.inputs.city.value !== ""
                          ? "City is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      required
                      sx={{ "& .MuiOutlinedInput-root": { height: 56 } }}
                    >
                      <InputLabel id="state_or_province-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="state_or_province-label"
                        id="state_or_province"
                        value={formState.inputs.state_or_province.value || ""}
                        onChange={(e) =>
                          inputHandler(
                            "state_or_province",
                            e.target.value,
                            e.target.value !== "",
                          )
                        }
                        input={<OutlinedInput label="State" />}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                        }}
                      >
                        {StateList.map((state) => (
                          <MenuItem key={state.label} value={state.label}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="zip_or_postal_code"
                      name="zip_or_postal_code"
                      label="Zip Code"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.zip_or_postal_code.value}
                      onChange={(e) =>
                        inputHandler(
                          "zip_or_postal_code",
                          e.target.value,
                          /^[0-9]{5}$/.test(e.target.value),
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.zip_or_postal_code.isValid &&
                        formState.inputs.zip_or_postal_code.value !== ""
                      }
                      helperText={
                        !formState.inputs.zip_or_postal_code.isValid &&
                        formState.inputs.zip_or_postal_code.value !== ""
                          ? "Zip code must be 5 digits."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="website"
                      name="website"
                      label="Website (optional)"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.website.value}
                      onChange={(e) =>
                        inputHandler("website", e.target.value, true)
                      }
                      className="form-field"
                      sx={inputStyle}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.inputs.us_federal_agency.value}
                          onChange={(e) =>
                            inputHandler(
                              "us_federal_agency",
                              e.target.checked,
                              true,
                            )
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "rgba(0, 0, 0, 0.87)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Are you looking to talk about services for or are you
                          calling from a United States Federal agency?
                        </Typography>
                      }
                    />
                  </Grid>
                  {!formState.inputs.us_federal_agency.value && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          sx={{ "& .MuiOutlinedInput-root": { height: 56 } }}
                        >
                          <InputLabel id="number-of-employees-label">
                            Number of Employees
                          </InputLabel>
                          <Select
                            labelId="number-of-employees-label"
                            id="number_of_employees"
                            value={formState.inputs.number_of_employees.value}
                            onChange={(e) =>
                              inputHandler(
                                "number_of_employees",
                                e.target.value,
                                true,
                              )
                            }
                            input={
                              <OutlinedInput label="Number of Employees" />
                            }
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                            }}
                          >
                            <MenuItem value="1-9">1-9</MenuItem>
                            <MenuItem value="10-19">10-19</MenuItem>
                            <MenuItem value="20-99">20-99</MenuItem>
                            <MenuItem value="100-499">100-499</MenuItem>
                            <MenuItem value="500-4999">500-4999</MenuItem>
                            <MenuItem value="5000 or more">5000 or more</MenuItem>
                            <MenuItem value="100000+">100000+</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          sx={{ "& .MuiOutlinedInput-root": { height: 56 } }}
                        >
                          <InputLabel id="products_of_interest-label">
                            Products of Interest
                          </InputLabel>
                          <Select
                            labelId="products_of_interest-label"
                            id="products_of_interest"
                            multiple
                            value={products}
                            onChange={(event) =>
                              setProducts(event.target.value)
                            }
                            input={
                              <OutlinedInput label="Products of Interest" />
                            }
                            renderValue={(selected) => selected.join(", ")}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                            }}
                          >
                            {ProductOptions.map((product) => (
                              <MenuItem key={product.value} value={product.value}>
                                {product.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <IconButton
                    onClick={handleNext}
                    color="primary"
                    sx={{ marginTop: "-10px" }}
                  >
                    Next
                    <ArrowForward />
                  </IconButton>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1} sx={{ marginBottom: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contact_first_name"
                      name="contact_first_name"
                      label="Contact First Name"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_first_name.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_first_name",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_first_name.isValid &&
                        formState.inputs.contact_first_name.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_first_name.isValid &&
                        formState.inputs.contact_first_name.value !== ""
                          ? "First name is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contact_last_name"
                      name="contact_last_name"
                      label="Contact Last Name"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_last_name.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_last_name",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_last_name.isValid &&
                        formState.inputs.contact_last_name.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_last_name.isValid &&
                        formState.inputs.contact_last_name.value !== ""
                          ? "Last name is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contact_job_title"
                      name="contact_job_title"
                      label="Contact Job Title"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_job_title.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_job_title",
                          e.target.value,
                          e.target.value.trim() !== "",
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_job_title.isValid &&
                        formState.inputs.contact_job_title.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_job_title.isValid &&
                        formState.inputs.contact_job_title.value !== ""
                          ? "Job title is required."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contact_phone"
                      name="contact_phone"
                      label="Contact Phone"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_phone.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_phone",
                          e.target.value,
                          /^[0-9]{10}$/.test(e.target.value),
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_phone.isValid &&
                        formState.inputs.contact_phone.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_phone.isValid &&
                        formState.inputs.contact_phone.value !== ""
                          ? "Phone number must be 10 digits."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contact_phone_extension"
                      name="contact_phone_extension"
                      label="Phone Extension (optional)"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_phone_extension.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_phone_extension",
                          e.target.value,
                          true,
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contact_mobile"
                      name="contact_mobile"
                      label="Contact Mobile (optional)"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_mobile.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_mobile",
                          e.target.value,
                          /^[0-9]{10}$/.test(e.target.value),
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_mobile.isValid &&
                        formState.inputs.contact_mobile.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_mobile.isValid &&
                        formState.inputs.contact_mobile.value !== ""
                          ? "Mobile number must be 10 digits."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contact_email"
                      name="contact_email"
                      label="Contact Email"
                      fullWidth
                      autoComplete="off"
                      value={formState.inputs.contact_email.value}
                      onChange={(e) =>
                        inputHandler(
                          "contact_email",
                          e.target.value,
                          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value),
                        )
                      }
                      className="form-field"
                      sx={inputStyle}
                      error={
                        !formState.inputs.contact_email.isValid &&
                        formState.inputs.contact_email.value !== ""
                      }
                      helperText={
                        !formState.inputs.contact_email.isValid &&
                        formState.inputs.contact_email.value !== ""
                          ? "Please enter a valid email."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                      <IconButton
                        onClick={handleBack}
                        color="primary"
                        sx={{ mr: 2 }}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!formState.isValid}
                        sx={{ height: 42, padding: "0 24px" }}
                      >
                        Create Lead
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};
 
export default NewLead;