import React, { useEffect, useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  CloudDownload as CloudDownloadIcon,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { AuthContext } from "../../shared/context/auth-context";
import "./UserUploads.css";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff",
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold",
        },
        columnHeaderTitle: {
          fontSize: "16px",
        },
        cell: {
          borderBottom: "1px solid #ddd",
        },
        row: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#f9f9f9",
          },
        },
        footerContainer: {
          backgroundColor: "#f1f3f4",
        },
      },
    },
  },
});

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(
    apiRef,
    (state) => state.pagination.pageCount
  );

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      {/* You can add custom toolbar items here */}
    </GridToolbarContainer>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const UserUploads = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [loadedLeads, setLoadedLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("user_uploads");
    }
  }, [auth]);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/uploads/${auth.user_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );

        const formattedData = responseData.map((lead) => ({
          ...lead,
          upload_date: formatDate(lead.upload_date),
        }));

        setLoadedLeads(formattedData);
      } catch (err) {
        console.error("Error fetching leads:", err);
      }
      setIsLoading(false);
    };

    if (auth && auth.token) {
      fetchLeads();
    }
  }, [sendRequest, auth]);

  const handleDownload = async (file_id) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/downloads/${file_id}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );

      const { url } = response;

      if (!url) {
        console.error("No download URL received.");
        return;
      }

      // Open the download link in a new tab
      window.open(url, "_blank");
    } catch (err) {
      console.error("Failed to download the file:", err);
    }
  };

  const columns = [
    {
      field: "file_id",
      headerName: "File ID",
      width: 150,
      hide: true,
      cellClassName: "centered-cell",
    },
    {
      field: "upload_date",
      headerName: "Upload Date",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "file_name",
      headerName: "File Name",
      width: 300,
      cellClassName: "centered-cell",
    },
    {
      field: "uploaded_volume",
      headerName: "Uploaded",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "enriched_volume",
      headerName: "Enriched",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_eloqua",
      headerName: "Sent to Eloqua",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_salesforce",
      headerName: "Sent to Salesforce",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_sent_to_dynamics",
      headerName: "Sent to Dynamics",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "leads_rejected",
      headerName: "Rejected",
      width: 175,
      cellClassName: "centered-cell",
    },
    {
      field: "ready_for_export",
      headerName: "Ready for Export",
      width: 175,
      cellClassName: "centered-cell",
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <PendingIcon style={{ color: "grey" }} />
        ),
    },
    {
      field: "download",
      headerName: "Download",
      sortable: false,
      cellClassName: "centered-cell",
      renderCell: (params) => (
        <CloudDownloadIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleDownload(params.row.file_id)}
          titleAccess={
            params.row.ready_for_export
              ? "Download Enriched File"
              : "Download Raw File"
          }
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedLeads && (
        <ThemeProvider theme={theme}>
          <div className="user-uploads-container">
            <div className="user-uploads-grid">
              <DataGrid
                rows={loadedLeads}
                columns={columns}
                getRowId={(row) => row.file_id}
                pageSize={10}
                pagination
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: CustomPagination,
                }}
                disableSelectionOnClick
                autoHeight
                classes={{
                  cell: "centered-cell",
                }}
              />
            </div>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default UserUploads;
