import React, { useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import Header from './Header';
import MainFooter from './MainFooter';

const MainNavigation = () => {
  
  return (
    <React.Fragment>
      <Header />
      <Toolbar />
      <MainFooter />
    </React.Fragment>
  );
};

export default MainNavigation;
