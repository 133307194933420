import React, { useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const FileUpload = ({ file, setFile, setFileData, expectedHeaders }) => {
  const fileInputRef = useRef();

  const removeDuplicates = (data) => {
    const uniqueSet = new Set();
    const uniqueData = [];
    let duplicateCount = 0;

    data.forEach((row) => {
      const rowString = JSON.stringify(row);
      if (!uniqueSet.has(rowString)) {
        uniqueSet.add(rowString);
        uniqueData.push(row);
      } else {
        duplicateCount++;
      }
    });

    return { uniqueData, duplicateCount };
  };

  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'csv') {
        Papa.parse(file, {
          complete: (results) => {
            const { uniqueData, duplicateCount } = removeDuplicates(results.data);
            resolve({ data: uniqueData, duplicateCount });
          },
          header: true,
          error: (error) => {
            reject(error);
          }
        });
      } else if (['xls', 'xlsx'].includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          
          const headers = jsonData[0];
          const rows = jsonData.slice(1).map(row => {
            return headers.reduce((acc, header, index) => {
              acc[header] = row[index];
              return acc;
            }, {});
          });
          
          const { uniqueData, duplicateCount } = removeDuplicates(rows);
          resolve({ data: uniqueData, duplicateCount });
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsArrayBuffer(file);
      } else {
        reject(new Error('Unsupported file type'));
      }
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        setFile(selectedFile);
        const { data, duplicateCount } = await parseFile(selectedFile);
        setFileData(data);
        if (duplicateCount > 0) {
          toast.info(`${duplicateCount} duplicate${duplicateCount > 1 ? 's were' : ' was'} identified and removed.`);
        }
      } catch (error) {
        console.error('Error parsing file:', error);
        toast.error('Error parsing file. Please check the file format and try again.');
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      try {
        setFile(droppedFile);
        const { data, duplicateCount } = await parseFile(droppedFile);
        setFileData(data);
        if (duplicateCount > 0) {
          toast.info(`${duplicateCount} duplicate${duplicateCount > 1 ? 's were' : ' was'} identified and removed.`);
        }
      } catch (error) {
        console.error('Error parsing file:', error);
        toast.error('Error parsing file. Please check the file format and try again.');
      }
    }
  };

  const downloadTemplate = () => {
    const csvContent = expectedHeaders.join(',');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>File Upload</Typography>
      <Typography variant="body1" gutterBottom>
        All leads should have a business name and either a valid phone number or email address. 
        Files must be in either .csv, .xls, or .xlsx formats.
      </Typography>
      <Button startIcon={<DownloadIcon />} onClick={downloadTemplate}>
        Download Template
      </Button>
      <Box
        sx={{
          border: '2px dashed #ccc',
          borderRadius: 2,
          padding: 3,
          textAlign: 'center',
          mt: 2
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          hidden
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".csv,.xls,.xlsx"
        />
        <Typography>Drag and drop your file here or</Typography>
        <Button
          variant="contained"
          startIcon={<FileUploadIcon />}
          onClick={() => fileInputRef.current.click()}
        >
          Choose File
        </Button>
      </Box>
      {file && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          File loaded: {file.name}
        </Typography>
      )}
    </Box>
  );
};

export default FileUpload;
