import React from 'react';
import { AlertCircle, Info } from 'lucide-react';

const Alert = ({ variant = 'default', children }) => {
  return (
    <div
      className={`rounded-lg border p-4 ${
        variant === 'destructive'
          ? 'border-red-200 bg-red-50 text-red-900'
          : 'border-blue-200 bg-blue-50 text-blue-900'
      }`}
      role="alert"
    >
      <div className="flex items-start space-x-3">
        {variant === 'destructive' ? (
          <AlertCircle className="h-4 w-4 text-red-600" />
        ) : (
          <Info className="h-4 w-4 text-blue-600" />
        )}
        <div className="flex-1 space-y-2">{children}</div>
      </div>
    </div>
  );
};

const AlertTitle = ({ children }) => {
  return <h5 className="font-medium leading-none tracking-tight">{children}</h5>;
};

const AlertDescription = ({ children }) => {
  return <div className="text-sm">{children}</div>;
};

export { Alert, AlertTitle, AlertDescription };
export default Alert;
