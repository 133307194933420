import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Users, Download, Filter } from "lucide-react";

// Date helper functions
const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const isThisWeek = (date) => {
  const today = new Date();
  const weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
  weekStart.setHours(0, 0, 0, 0);
  return date >= weekStart;
};

const TeamLeads = ({ teamMembers }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    totalLeads: 0,
    todayLeads: 0,
    weekLeads: 0,
  });

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const userTimeZone =
    localStorage.getItem("time_zone") ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        setIsLoading(true);
        const leadsPromises = teamMembers.map((member) =>
          sendRequest(
            `${process.env.REACT_APP_API_URL}/leads/user/${member.user_id}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
          ),
        );

        const allLeadsArrays = await Promise.all(leadsPromises);
        const combinedLeads = allLeadsArrays.flat().map((lead, index) => ({
          ...lead,
          id: index,
          teamMember:
            teamMembers.find((m) => m.user_id === lead.created_by)?.username ||
            "Unknown",
        }));

        setLeads(combinedLeads);

        setStats({
          totalLeads: combinedLeads.length,
          todayLeads: combinedLeads.filter((lead) =>
            isToday(new Date(lead.created_date)),
          ).length,
          weekLeads: combinedLeads.filter((lead) =>
            isThisWeek(new Date(lead.created_date)),
          ).length,
        });

        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching leads:", err);
        setIsLoading(false);
      }
    };

    if (teamMembers.length > 0) {
      fetchLeads();
    }
  }, [teamMembers, sendRequest, auth.token]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return new Date(dateString).toLocaleString("en-US", {
      timeZone: userTimeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const columns = [
    {
      field: "created_date",
      headerName: "Created Date",
      width: 180,
      valueFormatter: (params) => formatDate(params.value),
    },
    { field: "teamMember", headerName: "Team Member", width: 150 },
    { field: "business_name", headerName: "Business Name", width: 200 },
    { field: "contact_first_name", headerName: "First Name", width: 130 },
    { field: "contact_last_name", headerName: "Last Name", width: 130 },
    { field: "contact_email", headerName: "Email", width: 200 },
    { field: "contact_phone", headerName: "Phone", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "state_or_province", headerName: "State/Province", width: 130 },
    { field: "country", headerName: "Country", width: 130 },
    { field: "industry", headerName: "Industry", width: 200 },
    { field: "products_of_interest", headerName: "Products", width: 200 },
    { field: "campaign_id", headerName: "Campaign", width: 200 },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer className="bg-gray-50 px-4 py-2 border-b">
      <div className="flex justify-between w-full items-center">
        <div className="flex items-center space-x-2">
          <GridToolbarColumnsButton className="text-gray-700" />
          <GridToolbarFilterButton className="text-gray-700" />
          <GridToolbarExport className="text-gray-700" />
        </div>
        <GridToolbarQuickFilter className="w-64" />
      </div>
    </GridToolbarContainer>
  );

  return (
    <div className="p-6 pb-20 max-w-[1440px] mx-auto">
      {/* Stats Cards */}
      <div className="grid grid-cols-3 gap-6 mb-6 max-w-4xl mx-auto">
        <Card>
          <CardContent className="p-6">
            <div className="flex items-center justify-center h-full">
              <div className="flex flex-col items-center text-center">
                <Users className="h-8 w-8 text-blue-500 mb-2" />
                <p className="text-sm text-gray-500">Total Leads</p>
                <p className="text-2xl font-semibold">{stats.totalLeads}</p>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <div className="flex items-center justify-center h-full">
              <div className="flex flex-col items-center text-center">
                <Download className="h-8 w-8 text-green-500 mb-2" />
                <p className="text-sm text-gray-500">Today's Leads</p>
                <p className="text-2xl font-semibold">{stats.todayLeads}</p>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <div className="flex items-center justify-center h-full">
              <div className="flex flex-col items-center text-center">
                <Filter className="h-8 w-8 text-purple-500 mb-2" />
                <p className="text-sm text-gray-500">This Week</p>
                <p className="text-2xl font-semibold">{stats.weekLeads}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Main Data Grid */}
      <Card>
        <CardHeader>
          <CardTitle>Team Leads</CardTitle>
        </CardHeader>
        <CardContent>
          <div style={{ height: 700 }}>
            <DataGrid
              rows={leads}
              columns={columns}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
              density="compact"
              initialState={{
                pagination: {
                  pageSize: 100,
                },
                sorting: {
                  sortModel: [{ field: "created_date", sort: "desc" }],
                },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TeamLeads;
