import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  Save,
  Plus,
  Filter,
  X,
  ChevronDown,
  ChevronUp,
  Building,
  Users,
  MapPin,
  Briefcase,
  TrendingUp,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../user/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shared/components/UIElements/accordion";
import { Button } from "../../teamhub/components/ui/button";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../teamhub/components/ui/alert";

const AudienceBuilder = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [savedAudiences, setSavedAudiences] = useState([]);
  const [audienceName, setAudienceName] = useState("");
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [topCompanies, setTopCompanies] = useState([]);
  const [selectedJobLevels, setSelectedJobLevels] = useState([]);
  const [selectedCompanySizes, setSelectedCompanySizes] = useState([]);
  const [selectedRevenueRanges, setSelectedRevenueRanges] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedHQDivisions, setSelectedHQDivisions] = useState([]);
  const [excludedSectors, setExcludedSectors] = useState([]);
  const [excludedDepartments, setExcludedDepartments] = useState([]);
  const [excludedJobFunctions, setExcludedJobFunctions] = useState([]);
  const [selectedTopicClusters, setSelectedTopicClusters] = useState([]);
  const [saveStatus, setSaveStatus] = useState({ success: false, message: "" });
  const [filters, setFilters] = useState({
    personal: {
      jobTitle: [],
      jobLevel: [],
      jobRole: [],
      location: [],
    },
    company: {
      size: [],
      industry: [],
      revenue: [],
      location: [],
    },
  });
  const [exclusions, setExclusions] = useState({
    personal: {
      jobTitle: [],
      jobLevel: [],
      jobRole: [],
      location: [],
    },
    company: {
      size: [],
      industry: [],
      revenue: [],
      location: [],
    },
  });
  const [audienceSummary, setAudienceSummary] = useState({
    companies: 0,
    contacts: 0,
    workEmails: 0,
    directPhones: 0,
    mobilePhones: 0,
    addresses: 0,
  });

  const jobLevelOptions = [
    { value: "director", label: "Director" },
    { value: "owner", label: "Owner" },
    { value: "vp", label: "VP" },
    { value: "cxo", label: "CXO" },
    { value: "manager", label: "Manager" },
    { value: "individual", label: "Individual Contributor" },
  ];

  const companySizeOptions = [
    { value: "1-9", label: "Micro (1-9)" },
    { value: "10-49", label: "Small (10-49)" },
    { value: "50-199", label: "Medium-Small (50-199)" },
    { value: "200-499", label: "Medium (201-499)" },
    { value: "500-999", label: "Medium-Large (500-999)" },
    { value: "1000+", label: "Large (1,000+)" },
  ];

  const revenueRangeOptions = [
    { value: "<1MM", label: "Micro (<$1MM)" },
    { value: "1MM-10MM", label: "Small ($1MM-$10MM)" },
    { value: "10MM-50MM", label: "Medium-Small ($10MM-$50MM)" },
    { value: "50MM-100MM", label: "Medum-Large ($50MM-$100MM)" },
    { value: "100MM-200MM", label: "Large ($100MM-$200MM)" },
    { value: "200MM-1B", label: "XLarge ($200MM-$1B)" },
    { value: "1B+", label: "XXLarge ($1B+)" },
  ];

  const sectorOptions = [
    { value: "agriculture", label: "Agriculture" },
    { value: "business-services", label: "Business Services" },
    { value: "education", label: "Education" },
    { value: "energy-utilities", label: "Energy and Utilities" },
    { value: "finance", label: "Finance" },
    { value: "government", label: "Government and Public Sector" },
    { value: "healthcare", label: "Healthcare" },
    { value: "hospitality", label: "Hospitality and Entertainment" },
    { value: "insurance", label: "Insurance" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "media-communications", label: "Media and Communications" },
    { value: "membership-organizations", label: "Membership Organizations" },
    { value: "mining-extraction", label: "Mining and Extraction" },
    { value: "non-profit", label: "Non-Profit and NGOs" },
    { value: "personal-services", label: "Personal Services" },
    { value: "quick-serve-restaurants", label: "Quick Serve Restaurants" },
    {
      value: "real-estate-construction",
      label: "Real Estate and Construction",
    },
    { value: "retail-ecommerce", label: "Retail and E-commerce" },
    { value: "technology", label: "Technology" },
    { value: "telecommunications", label: "Telecommunications" },
    {
      value: "transportation-logistics",
      label: "Transportation and Logistics",
    },
    { value: "wholesale-trade", label: "Wholesale Trade" },
  ];

  const departmentOptions = [
    { value: "executive-leadership", label: "Executive Leadership" },
    { value: "operations", label: "Operations" },
    { value: "engineering-technical", label: "Engineering & Technical" },
    { value: "finance", label: "Finance" },
    { value: "human-resources", label: "Human Resources" },
    { value: "marketing", label: "Marketing" },
    { value: "sales-business-dev", label: "Sales & Business Development" },
    { value: "customer-service", label: "Customer Service & Support" },
    { value: "legal-compliance", label: "Legal & Compliance" },
    { value: "product-management", label: "Product Management" },
    { value: "procurement", label: "Procurement & Purchasing" },
    { value: "facilities", label: "Facilities & Real Estate" },
    { value: "health-safety", label: "Health & Safety" },
  ];

  const locationOptions = [
    { value: "headquarter", label: "Headquarter" },
    { value: "other-location", label: "Other Location" },
  ];

  const hqDivisionOptions = [
    { value: "central", label: "Central" },
    { value: "northeast", label: "Northeast" },
    { value: "west", label: "West" },
    { value: "out-of-footprint", label: "Out of Footprint" },
  ];

  const jobFunctionsByDepartment = [
    {
      department: "Executive Leadership",
      functions: [
        { value: "c-suite", label: "C-Suite" },
        { value: "board-of-directors", label: "Board of Directors" },
        {
          value: "strategy-corp-dev",
          label: "Strategy & Corporate Development",
        },
      ],
    },
    {
      department: "Operations",
      functions: [
        { value: "operations-management", label: "Operations Management" },
        { value: "supply-chain-logistics", label: "Supply Chain & Logistics" },
        { value: "quality-assurance", label: "Quality Assurance" },
      ],
    },
    {
      department: "Engineering & Technical",
      functions: [
        { value: "engineering", label: "Engineering" },
        { value: "software-development", label: "Software Development" },
        { value: "it-technical-support", label: "IT & Technical Support" },
        { value: "research-development", label: "Research & Development" },
        { value: "data-science-analytics", label: "Data Science & Analytics" },
      ],
    },
    {
      department: "Finance",
      functions: [
        {
          value: "financial-planning-analysis",
          label: "Financial Planning & Analysis",
        },
        { value: "accounting", label: "Accounting" },
        { value: "audit-compliance", label: "Audit & Compliance" },
        { value: "investor-relations", label: "Investor Relations" },
      ],
    },
    {
      department: "Human Resources",
      functions: [
        {
          value: "talent-acquisition",
          label: "Talent Acquisition & Recruitment",
        },
        { value: "employee-relations", label: "Employee Relations" },
        { value: "learning-development", label: "Learning & Development" },
        { value: "compensation-benefits", label: "Compensation & Benefits" },
      ],
    },
    {
      department: "Marketing",
      functions: [
        { value: "brand-communications", label: "Brand & Communications" },
        { value: "product-marketing", label: "Product Marketing" },
        { value: "digital-marketing", label: "Digital Marketing" },
        { value: "content-creative", label: "Content & Creative Services" },
      ],
    },
    {
      department: "Sales & Business Development",
      functions: [
        { value: "sales-operations", label: "Sales Operations" },
        { value: "business-development", label: "Business Development" },
        { value: "account-management", label: "Account Management" },
        { value: "customer-success", label: "Customer Success" },
      ],
    },
    {
      department: "Customer Service & Support",
      functions: [
        { value: "customer-service", label: "Customer Service" },
        { value: "technical-support", label: "Technical Support" },
        {
          value: "client-success-management",
          label: "Client Success Management",
        },
      ],
    },
    {
      department: "Legal & Compliance",
      functions: [
        { value: "legal-department", label: "Legal Department" },
        {
          value: "compliance-regulatory",
          label: "Compliance & Regulatory Affairs",
        },
        { value: "risk-management", label: "Risk Management" },
        {
          value: "intellectual-property",
          label: "Intellectual Property Management",
        },
      ],
    },
    {
      department: "Product Management",
      functions: [
        { value: "product-development", label: "Product Development" },
        { value: "product-strategy", label: "Product Strategy & Roadmap" },
        { value: "ux-design", label: "User Experience (UX) & Design" },
      ],
    },
    {
      department: "Procurement & Purchasing",
      functions: [
        { value: "vendor-management", label: "Vendor Management" },
        { value: "sourcing", label: "Sourcing" },
        { value: "contract-management", label: "Contract Management" },
      ],
    },
    {
      department: "Facilities & Real Estate",
      functions: [
        { value: "facilities-management", label: "Facilities Management" },
        {
          value: "real-estate-property",
          label: "Real Estate & Property Management",
        },
        { value: "workplace-services", label: "Workplace Services" },
      ],
    },
    {
      department: "Health & Safety",
      functions: [
        { value: "ehs", label: "Environmental Health & Safety (EHS)" },
        { value: "occupational-health", label: "Occupational Health" },
        { value: "security", label: "Security" },
      ],
    },
  ];

  const topicClusterOptions = [
    { value: "security", label: "Security" },
    { value: "internet", label: "Internet" },
    { value: "sd-wan", label: "SD-WAN" },
    { value: "wireless", label: "Wireless" },
    { value: "voip-telephony", label: "VoIP and Telephony" },
    { value: "vpn", label: "VPN" },
    { value: "managed-services", label: "Managed Services" },
    { value: "competitors", label: "Competitors" },
    { value: "metro-e", label: "Metro-E" },
    { value: "mobile", label: "Mobile" },
    { value: "movers", label: "Movers" },
  ];

  const handleJobLevelSelect = (event) => {
    const value = event.target.value;
    if (!selectedJobLevels.includes(value)) {
      setSelectedJobLevels([...selectedJobLevels, value]);
    }
    // Reset the select value
    event.target.value = "";
  };

  const removeJobLevel = (levelToRemove) => {
    setSelectedJobLevels(
      selectedJobLevels.filter((level) => level !== levelToRemove),
    );
  };

  const handleCompanySizeSelect = (event) => {
    const value = event.target.value;
    if (!selectedCompanySizes.includes(value)) {
      setSelectedCompanySizes([...selectedCompanySizes, value]);
    }
    event.target.value = "";
  };

  const handleRevenueRangeSelect = (event) => {
    const value = event.target.value;
    if (!selectedRevenueRanges.includes(value)) {
      setSelectedRevenueRanges([...selectedRevenueRanges, value]);
    }
    event.target.value = "";
  };

  const handleSectorSelect = (event) => {
    const value = event.target.value;
    if (!selectedSectors.includes(value)) {
      setSelectedSectors([...selectedSectors, value]);
    }
    event.target.value = "";
  };

  const removeCompanySize = (sizeToRemove) => {
    setSelectedCompanySizes(
      selectedCompanySizes.filter((size) => size !== sizeToRemove),
    );
  };

  const removeRevenueRange = (rangeToRemove) => {
    setSelectedRevenueRanges(
      selectedRevenueRanges.filter((range) => range !== rangeToRemove),
    );
  };

  const removeSector = (sectorToRemove) => {
    setSelectedSectors(
      selectedSectors.filter((sector) => sector !== sectorToRemove),
    );
  };

  const handleDepartmentSelect = (event) => {
    const value = event.target.value;
    if (!selectedDepartments.includes(value)) {
      setSelectedDepartments([...selectedDepartments, value]);
    }
    event.target.value = "";
  };

  const removeDepartment = (departmentToRemove) => {
    setSelectedDepartments(
      selectedDepartments.filter((dept) => dept !== departmentToRemove),
    );
  };

  const handleLocationSelect = (event) => {
    const value = event.target.value;
    if (!selectedLocations.includes(value)) {
      setSelectedLocations([...selectedLocations, value]);
    }
    event.target.value = "";
  };

  const handleHQDivisionSelect = (event) => {
    const value = event.target.value;
    if (!selectedHQDivisions.includes(value)) {
      setSelectedHQDivisions([...selectedHQDivisions, value]);
    }
    event.target.value = "";
  };

  const removeLocation = (locationToRemove) => {
    setSelectedLocations(
      selectedLocations.filter((loc) => loc !== locationToRemove),
    );
  };

  const removeHQDivision = (divisionToRemove) => {
    setSelectedHQDivisions(
      selectedHQDivisions.filter((div) => div !== divisionToRemove),
    );
  };

  const handleJobFunctionSelect = (event) => {
    const value = event.target.value;
    if (!selectedJobFunctions.includes(value)) {
      setSelectedJobFunctions([...selectedJobFunctions, value]);
    }
    event.target.value = "";
  };

  const removeJobFunction = (functionToRemove) => {
    setSelectedJobFunctions(
      selectedJobFunctions.filter((func) => func !== functionToRemove),
    );
  };

  const handleExcludedSectorSelect = (event) => {
    const value = event.target.value;
    if (!excludedSectors.includes(value)) {
      setExcludedSectors([...excludedSectors, value]);
    }
    event.target.value = "";
  };

  const handleExcludedDepartmentSelect = (event) => {
    const value = event.target.value;
    if (!excludedDepartments.includes(value)) {
      setExcludedDepartments([...excludedDepartments, value]);
    }
    event.target.value = "";
  };

  const handleExcludedJobFunctionSelect = (event) => {
    const value = event.target.value;
    if (!excludedJobFunctions.includes(value)) {
      setExcludedJobFunctions([...excludedJobFunctions, value]);
    }
    event.target.value = "";
  };

  const removeExcludedSector = (sectorToRemove) => {
    setExcludedSectors(
      excludedSectors.filter((sector) => sector !== sectorToRemove),
    );
  };

  const removeExcludedDepartment = (departmentToRemove) => {
    setExcludedDepartments(
      excludedDepartments.filter((dept) => dept !== departmentToRemove),
    );
  };

  const removeExcludedJobFunction = (functionToRemove) => {
    setExcludedJobFunctions(
      excludedJobFunctions.filter((func) => func !== functionToRemove),
    );
  };

  const handleTopicClusterSelect = (event) => {
    const value = event.target.value;
    if (!selectedTopicClusters.includes(value)) {
      setSelectedTopicClusters([...selectedTopicClusters, value]);
    }
    event.target.value = "";
  };

  const removeTopicCluster = (clusterToRemove) => {
    setSelectedTopicClusters(
      selectedTopicClusters.filter((cluster) => cluster !== clusterToRemove),
    );
  };

  const handleSaveAudience = async () => {
    if (!audienceName.trim()) {
      setSaveStatus({
        success: false,
        message: "Please enter an audience name"
      });
      return;
    }

    try {
      // Add API call here
      
      setSaveStatus({
        success: true,
        message: "Audience saved successfully"
      });
      
      // Reset status after 3 seconds
      setTimeout(() => {
        setSaveStatus({ success: false, message: "" });
      }, 3000);
    } catch (err) {
      setSaveStatus({
        success: false,
        message: "Failed to save audience"
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto grid grid-cols-12 gap-6 pb-24">
        {/* Left Column - Filters */}
        <div className="col-span-8">
          <div className="space-y-6">
            {/* Audience Name Card with Save Button */}
            <Card>
              <CardHeader>
                <div className="flex justify-between items-center">
                  <CardTitle>Audience Name</CardTitle>
                  <Button
                    onClick={handleSaveAudience}
                    className="bg-purple-600 hover:bg-purple-700 text-white"
                  >
                    <Save className="w-4 h-4 mr-2" />
                    Save Audience
                  </Button>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Enter audience name"
                    value={audienceName}
                    onChange={(e) => setAudienceName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                  />
                  {saveStatus.message && (
                    <Alert
                      className={saveStatus.success ? "bg-green-50 text-green-700 border-green-200" : "bg-red-50 text-red-700 border-red-200"}
                    >
                      <AlertTitle>
                        {saveStatus.success ? "Success" : "Error"}
                      </AlertTitle>
                      <AlertDescription>
                        {saveStatus.message}
                      </AlertDescription>
                    </Alert>
                  )}
                </div>
              </CardContent>
            </Card>

            {/* Saved Audiences */}
            <Card>
              <CardHeader>
                <CardTitle>Saved Audiences</CardTitle>
              </CardHeader>
              <CardContent>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                  onChange={(e) => setSelectedAudience(e.target.value)}
                >
                  <option value="">Select a saved audience</option>
                  {savedAudiences.map((audience) => (
                    <option key={audience.id} value={audience.id}>
                      {audience.name}
                    </option>
                  ))}
                </select>
              </CardContent>
            </Card>

            {/* Filters */}
            <Card>
              <CardHeader>
                <CardTitle>Define Audience</CardTitle>
              </CardHeader>
              <CardContent>
                <Accordion type="single" collapsible className="w-full">
                  {/* Contact Filters */}
                  <AccordionItem value="contact-filters">
                    <AccordionTrigger className="hover:no-underline">
                      <div className="flex items-center gap-2">
                        <Users className="w-4 h-4" />
                        <span>Contact Filters</span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4 pt-4">
                        {/* Job Level */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Job Level
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleJobLevelSelect}
                            value=""
                          >
                            <option value="">Select job level</option>
                            {jobLevelOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          {/* Selected Job Levels Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedJobLevels.map((level) => {
                              const option = jobLevelOptions.find(
                                (opt) => opt.value === level,
                              );
                              return (
                                <div
                                  key={level}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeJobLevel(level)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Department */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Department
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleDepartmentSelect}
                            value=""
                          >
                            <option value="">Select department</option>
                            {departmentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          {/* Selected Departments Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedDepartments.map((department) => {
                              const option = departmentOptions.find(
                                (opt) => opt.value === department,
                              );
                              return (
                                <div
                                  key={department}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeDepartment(department)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Job Function */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Job Function
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleJobFunctionSelect}
                            value=""
                          >
                            <option value="">Select job function</option>
                            {jobFunctionsByDepartment.map((dept) => (
                              <optgroup
                                key={dept.department}
                                label={dept.department}
                              >
                                {dept.functions.map((func) => (
                                  <option key={func.value} value={func.value}>
                                    {func.label}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </select>

                          {/* Selected Job Functions Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedJobFunctions.map((funcValue) => {
                              const func = jobFunctionsByDepartment
                                .flatMap((dept) => dept.functions)
                                .find((f) => f.value === funcValue);
                              return (
                                <div
                                  key={funcValue}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{func?.label}</span>
                                  <button
                                    onClick={() => removeJobFunction(funcValue)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Location */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Location
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleLocationSelect}
                            value=""
                          >
                            <option value="">Select location</option>
                            {locationOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          {/* Selected Locations Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedLocations.map((location) => {
                              const option = locationOptions.find(
                                (opt) => opt.value === location,
                              );
                              return (
                                <div
                                  key={location}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeLocation(location)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Company Filters */}
                  <AccordionItem value="company-filters">
                    <AccordionTrigger className="hover:no-underline">
                      <div className="flex items-center gap-2">
                        <Building className="w-4 h-4" />
                        <span>Company Filters</span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4 pt-4">
                        {/* Company Size */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Company Size
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleCompanySizeSelect}
                            value=""
                          >
                            <option value="">Select company size</option>
                            {companySizeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedCompanySizes.map((size) => {
                              const option = companySizeOptions.find(
                                (opt) => opt.value === size,
                              );
                              return (
                                <div
                                  key={size}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeCompanySize(size)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Sector
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleSectorSelect}
                            value=""
                          >
                            <option value="">Select sector</option>
                            {sectorOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedSectors.map((sector) => {
                              const option = sectorOptions.find(
                                (opt) => opt.value === sector,
                              );
                              return (
                                <div
                                  key={sector}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeSector(sector)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Annual Revenue
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleRevenueRangeSelect}
                            value=""
                          >
                            <option value="">Select revenue range</option>
                            {revenueRangeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedRevenueRanges.map((range) => {
                              const option = revenueRangeOptions.find(
                                (opt) => opt.value === range,
                              );
                              return (
                                <div
                                  key={range}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeRevenueRange(range)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Headquarter Division
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleHQDivisionSelect}
                            value=""
                          >
                            <option value="">
                              Select headquarter division
                            </option>
                            {hqDivisionOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedHQDivisions.map((division) => {
                              const option = hqDivisionOptions.find(
                                (opt) => opt.value === division,
                              );
                              return (
                                <div
                                  key={division}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeHQDivision(division)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Surging Intent
                          </label>
                          <div className="flex items-center gap-2 mt-1">
                            <select
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                              onChange={handleTopicClusterSelect}
                              value=""
                            >
                              <option value="">Select topic cluster</option>
                              {topicClusterOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* Selected Topic Clusters Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedTopicClusters.map((cluster) => {
                              const option = topicClusterOptions.find(
                                (opt) => opt.value === cluster,
                              );
                              return (
                                <div
                                  key={cluster}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <TrendingUp className="w-3 h-3 mr-1" />
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeTopicCluster(cluster)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Exclusions */}
                  <AccordionItem value="exclusions">
                    <AccordionTrigger className="hover:no-underline">
                      <div className="flex items-center gap-2">
                        <Filter className="w-4 h-4" />
                        <span>Exclusions</span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4 pt-4">
                        {/* Excluded Sectors */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Excluded Sectors
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleExcludedSectorSelect}
                            value=""
                          >
                            <option value="">Select sectors to exclude</option>
                            {sectorOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          {/* Excluded Sectors Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {excludedSectors.map((sector) => {
                              const option = sectorOptions.find(
                                (opt) => opt.value === sector,
                              );
                              return (
                                <div
                                  key={sector}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() => removeExcludedSector(sector)}
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Excluded Departments */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Excluded Departments
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleExcludedDepartmentSelect}
                            value=""
                          >
                            <option value="">
                              Select departments to exclude
                            </option>
                            {departmentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>

                          {/* Excluded Departments Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {excludedDepartments.map((department) => {
                              const option = departmentOptions.find(
                                (opt) => opt.value === department,
                              );
                              return (
                                <div
                                  key={department}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{option?.label}</span>
                                  <button
                                    onClick={() =>
                                      removeExcludedDepartment(department)
                                    }
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Excluded Job Functions */}
                        <div>
                          <label className="text-sm font-medium text-gray-700">
                            Excluded Job Functions
                          </label>
                          <select
                            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-sm"
                            onChange={handleExcludedJobFunctionSelect}
                            value=""
                          >
                            <option value="">
                              Select job functions to exclude
                            </option>
                            {jobFunctionsByDepartment.map((dept) => (
                              <optgroup
                                key={dept.department}
                                label={dept.department}
                              >
                                {dept.functions.map((func) => (
                                  <option key={func.value} value={func.value}>
                                    {func.label}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </select>

                          {/* Excluded Job Functions Chips */}
                          <div className="flex flex-wrap gap-2 mt-2">
                            {excludedJobFunctions.map((funcValue) => {
                              const func = jobFunctionsByDepartment
                                .flatMap((dept) => dept.functions)
                                .find((f) => f.value === funcValue);
                              return (
                                <div
                                  key={funcValue}
                                  className="inline-flex items-center px-3 py-1 rounded-md bg-purple-100 text-purple-700"
                                >
                                  <span>{func?.label}</span>
                                  <button
                                    onClick={() =>
                                      removeExcludedJobFunction(funcValue)
                                    }
                                    className="ml-2 focus:outline-none"
                                  >
                                    <X className="w-3 h-3" />
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardContent>
            </Card>
          </div>
        </div>

        {/* Right Column - Summary */}
        <div className="col-span-4 space-y-6">
          {/* Audience Summary */}
          <Card>
            <CardHeader>
              <CardTitle>Audience Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Companies</span>
                  <span className="font-medium">
                    {audienceSummary.companies.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Contacts</span>
                  <span className="font-medium">
                    {audienceSummary.contacts.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Work Emails</span>
                  <span className="font-medium">
                    {audienceSummary.workEmails.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Direct Phones</span>
                  <span className="font-medium">
                    {audienceSummary.directPhones.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Mobile Phones</span>
                  <span className="font-medium">
                    {audienceSummary.mobilePhones.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Addresses</span>
                  <span className="font-medium">
                    {audienceSummary.addresses.toLocaleString()}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Top Companies */}
          <Card>
            <CardHeader>
              <CardTitle>Top Companies</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {topCompanies.map((company, index) => (
                  <div
                    key={company.id}
                    className="flex justify-between items-center p-2 hover:bg-gray-50 rounded-lg"
                  >
                    <span className="text-sm">{company.name}</span>
                    <span className="text-sm text-gray-500">
                      {company.contactCount} contacts
                    </span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AudienceBuilder;
