import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { AuthContext } from "../../shared/context/auth-context";
import SetUp from "../components/steps/SetUp";
import FileUpload from "../components/steps/FileUpload";
import EnrichmentFieldSelector from "../components/steps/EnrichmentFieldSelector";
import MapFields from "../components/steps/MapFields";
import Review from "../components/steps/Review";
import Submit from "../components/steps/Submit";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "react-toastify/dist/ReactToastify.css";

const getSteps = (enrichmentOnly) => {
  const baseSteps = ["Set Up", "File Upload", "Map Fields", "Review", "Submit"];
  if (enrichmentOnly) {
    return [
      "Set Up",
      "File Upload",
      "Select Enrichment Fields",
      "Map Fields",
      "Review",
      "Submit",
    ];
  }
  return baseSteps;
};

const expectedHeaders = [
  "business_name",
  "address",
  "address2",
  "city",
  "state_or_province",
  "zip_or_postal_code",
  "country",
  "website",
  "contact_first_name",
  "contact_last_name",
  "contact_job_title",
  "contact_phone",
  "contact_phone_extension",
  "contact_mobile",
  "contact_email",
  "contact_supplemental_email",
  "contact_linkedin_url",
  "number_of_employees",
  "products_of_interest",
  "hq_source_provided",
  "us_federal_agency",
  "lead_notes",
  "custom_questions",
  "asset_1",
  "asset_2",
  "asset_3",
  "campaign_name",
  "lead_source",
  "event_name",
];

const formatHeader = (header) => {
  return header
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const UploadList = () => {
  // Base state
  const [activeStep, setActiveStep] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [eventName, setEventName] = useState("");
  const [destinations, setDestinations] = useState({
    send_to_nurture: false,
    send_to_crm: false,
    enrichment_only: false,
  });
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [headerMapping, setHeaderMapping] = useState({});
  const [needsHeaderMapping, setNeedsHeaderMapping] = useState(false);
  const [headerMappingDialogOpen, setHeaderMappingDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [uploadedHeaders, setUploadedHeaders] = useState([]);
  const [mappingsConfirmed, setMappingsConfirmed] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const auth = useContext(AuthContext);
  const history = useHistory();

  const [selectedEnrichmentFields, setSelectedEnrichmentFields] = useState([]);

  useEffect(() => {
    if (fileData.length > 0) {
      const uploadedHeaders = Object.keys(fileData[0]);

      // Map all uploaded headers, defaulting to "do_not_map" for headers without matches
      const initialMapping = uploadedHeaders.reduce((acc, header) => {
        acc[header] = getMappedHeader(header);
        return acc;
      }, {});

      setHeaderMapping(initialMapping);
      setUploadedHeaders(uploadedHeaders);
      setMappingsConfirmed(true);
      setNeedsHeaderMapping(false);
    }
  }, [fileData]);

  const MapFields = ({ headerMapping, setHeaderMapping, uploadedHeaders }) => {
    const handleDropdownChange = (header, selectedValue) => {
      setHeaderMapping((prevMapping) => ({
        ...prevMapping,
        [header]: selectedValue,
      }));
    };

    const getFieldStyle = (header) => ({
      width: "250px",
      padding: "8px",
      fontSize: "16px",
      backgroundColor:
        headerMapping[header] === "do_not_map" ? "#f8d7da" : "#d4edda",
      borderColor:
        headerMapping[header] === "do_not_map" ? "#f5c6cb" : "#c3e6cb",
      borderRadius: "4px",
      appearance: "none",
      position: "relative",
      paddingRight: "30px",
    });

    return (
      <div style={{ padding: "20px" }}>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Map the fields from your file to the expected template fields.
        </h3>
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            justifyContent: "space-between",
            padding: "10px 0",
            borderBottom: "2px solid #ddd",
          }}
        >
          <div style={{ width: "30%", textAlign: "center" }}>Source Field</div>
          <div style={{ width: "30%", textAlign: "center" }}>Target Field</div>
          <div style={{ width: "30%", textAlign: "center" }}>Do Not Map</div>
        </div>
        {Object.keys(headerMapping).map((header) => (
          <div
            key={header}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 0",
              borderBottom: "1px solid #eee",
            }}
          >
            <div
              style={{
                width: "30%",
                paddingLeft: "10px",
                textAlign: "center",
                color: "#333",
              }}
            >
              {formatHeader(header)}
            </div>
            <div
              style={{
                width: "30px",
                textAlign: "center",
                borderTop: "1px dotted #999",
                position: "relative",
                marginRight: "10px",
              }}
            >
              <ArrowForwardIosIcon
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "-6px",
                  right: "-12px",
                  color: "#999",
                }}
              />
            </div>
            <div
              style={{
                width: "30%",
                textAlign: "center",
                position: "relative",
              }}
            >
              <select
                value={headerMapping[header] || "do_not_map"}
                onChange={(e) => handleDropdownChange(header, e.target.value)}
                style={getFieldStyle(header)}
              >
                {expectedHeaders.map((expectedHeader) => (
                  <option
                    key={expectedHeader}
                    value={expectedHeader}
                    style={{ backgroundColor: "white", color: "#333" }}
                  >
                    {formatHeader(expectedHeader)}
                  </option>
                ))}
                <option
                  value="do_not_map"
                  style={{ backgroundColor: "white", color: "#333" }}
                >
                  Do Not Map
                </option>
              </select>
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "-25px",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  fontSize: "12px",
                  color: "#666",
                }}
              >
                ▼
              </span>
            </div>
            <div style={{ width: "30%", textAlign: "center" }}>
              <Tooltip title="Do Not Map This Field" arrow>
                <RemoveCircleIcon
                  onClick={() => handleDropdownChange(header, "do_not_map")}
                  style={{
                    color:
                      headerMapping[header] === "do_not_map"
                        ? "#d9534f"
                        : "#6c757d",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const headerVariations = {
    business_name: [
      "business_name",
      "biz_name",
      "buissname",
      "company_name",
      "company",
      "businessname",
      "organization",
      "orgname",
      "org name",
      "business",
      "organizationname",
      "organization name",
    ],
    address: [
      "address",
      "addr",
      "location",
      "address1",
      "streetaddress",
      "street address",
      "street",
      "addressline1",
      "addressline_1",
      "address 1",
      "address line 1",
    ],
    address2: [
      "address2",
      "address 2",
      "address_line_2",
      "addr2",
      "suite",
      "addressline2",
      "secondaryaddress",
      "secondary address",
    ],
    city: ["city", "town", "municipality", "locality", "place"],
    state_or_province: [
      "state",
      "province",
      "stateorprovince",
      "state or province",
      "state-or-province",
      "region",
      "territory",
      "statename",
      "state name",
      "province name",
      "provincename",
    ],
    zip_or_postal_code: [
      "zip",
      "postal_code",
      "zipcode",
      "zip code",
      "postal",
      "postal code",
      "postalcode",
      "zip code",
      "zipcode",
      "postcode",
      "post code",
    ],
    country: [
      "country",
      "nation",
      "countryname",
      "country name",
      "nationname",
      "nation name",
      "countryorregion",
      "country/region",
      "country/regionname",
      "country/region name",
    ],
    website: [
      "website",
      "webpage",
      "url",
      "site",
      "homepage",
      "webaddress",
      "websiteurl",
      "webpageurl",
      "siteurl",
      "homepageurl",
      "web",
      "webpageaddress",
      "webpageurl",
      "website url",
      "site url",
      "site address",
      "web address",
      "webpage address",
      "webpage url",
    ],
    contact_first_name: [
      "first_name",
      "contactfirstname",
      "contact first name",
      "fname",
      "firstname",
      "givenname",
      "forename",
      "contactforename",
      "contactgivenname",
      "first name",
      "given name",
      "fore name",
    ],
    contact_last_name: [
      "last_name",
      "contact_last_name",
      "lname",
      "lastname",
      "surname",
      "familyname",
      "last name",
      "family name",
      "contactfamilyname",
      "contactsurname",
      "contact surname",
    ],
    contact_job_title: [
      "job_title",
      "position",
      "role",
      "jobtitle",
      "designation",
      "title",
      "jobposition",
      "positiontitle",
      "job title",
      "job position",
      "job role",
      "position title",
    ],
    contact_phone: [
      "phone",
      "contact_phone",
      "telephone",
      "phonenumber",
      "contactnumber",
      "contactphonenumber",
      "phone number",
      "contact phone number",
      "contactphone",
      "contactphone_number",
      "contact_phone_number",
      "contact number",
      "telephone number",
      "telephone_number",
    ],
    contact_phone_extension: [
      "phone_extension",
      "ext",
      "extension",
      "phoneext",
      "contactphoneext",
      "phone extension",
      "contact phone extension",
      "contactphoneextension",
      "contactphone_ext",
      "contact_phone_ext",
      "contact_phone_extension",
      "phone_ext",
      "contactphone_ext",
      "contact_phone_ext",
    ],
    contact_mobile: [
      "mobile",
      "cell",
      "contact_mobile",
      "contactmobile",
      "contact mobile",
      "mobile_phone",
      "mobile phone",
      "mobilephone",
      "cellphone",
      "mobilenumber",
      "mobile number",
      "mobile_number",
      "cellphonenumber",
    ],
    contact_email: [
      "email",
      "email_id",
      "email id",
      "emailid",
      "emailID",
      "contact_email",
      "contactemail",
      "contact email",
      "contactmail",
      "contact mail",
      "contact_mail",
      "mail",
      "emailaddress",
      "email_address",
      "email address",
      "contactemailaddress",
    ],
    contact_supplemental_email: [
      "supplemental_email",
      "altemail",
      "alternateemail",
      "alternate email",
      "alternate_email",
      "alternate_id",
      "alternate_email_id",
      "alternate email id",
      "secondaryemail",
      "secondary_email",
      "secondary email",
      "backupemail",
      "backup_email",
      "backup email",
      "additionalemail",
      "additional_email",
      "additional email",
    ],
    contact_linkedin_url: [
      "linkedin",
      "linkedinurl",
      "linkedin_url",
      "linkedin url",
      "profileurl",
      "profile url",
      "profile_url",
      "linkedinprofile",
      "linkedin_profile",
      "linkedin profile",
      "linkedinProfile",
      "linkedinlink",
      "linkedinprofileurl",
      "linkedin profile url",
      "linkedin_profile_url",
      "linkedinid",
      "linkedin id",
      "linkedin_id",
      "linkedinidurl",
      "linkedin id url",
      "linkedin_id_url",
    ],
    number_of_employees: [
      "number_of_employees",
      "number of employees",
      "numberofemployees",
      "no. of employees",
      "employees",
      "staffcount",
      "staff_count",
      "staff count",
      "total_employees",
      "total employees",
      "totalemployees",
      "employeecount",
      "employee_count",
      "employee count",
      "numemployees",
      "team_size",
      "teamsize",
      "team size",
    ],
    products_of_interest: [
      "products of interest",
      "productsofinterest",
      "products",
      "interest",
      "interests",
      "productinterest",
      "interestedproducts",
    ],
    hq_source_provided: [
      "hqsource",
      "hq source",
      "headquarter_source",
      "headquarter source",
      "headquartersource",
      "sourceprovided",
      "headquarters",
      "hq_source",
      "headoffice",
      "head office",
      "head_office",
      "mainofficesource",
    ],
    us_federal_agency: [
      "usfederalagency",
      "us fderal agency",
      "U.S federal agency",
      "federalagency",
      "federal_agency",
      "federal agency",
      "usagency",
      "us_agency",
      "us agency",
      "usfedagency",
    ],
    lead_notes: [
      "leadnotes",
      "lead notes",
      "notes",
      "remarks",
      "additionalnotes",
      "additional_notes",
      "additional notes",
      "leadcomments",
      "lead_comments",
      "lead comments",
    ],
    custom_questions: [
      "customquestions",
      "custom questions",
      "questions",
      "survey",
      "customsurvey",
      "custom_survey",
      "custom survey",
      "extra_questions",
      "extra questions",
      "extraquestions",
    ],
    asset_1: [
      "asset1",
      "asset 1",
      "asset_1",
      "file1",
      "file 1",
      "file_1",
      "document1",
      "document_1",
      "document 1",
      "attachment1",
      "attachemnt_1",
      "attachment 1",
    ],
    asset_2: [
      "asset2",
      "asset_2",
      "asset 2",
      "file2",
      "file_2",
      "file 2",
      "document2",
      "document 2",
      "document_2",
      "attachment2",
      "attachment 2",
      "attachment_2",
    ],
    asset_3: [
      "asset3",
      "asset_3",
      "asset 3",
      "file3",
      "file_3",
      "file 3",
      "document3",
      "document_3",
      "document3",
      "attachment3",
      "attachment_3",
      "attachment 3",
    ],
    file_lead_source: [
      "leadsource",
      "lead_source",
      "leadsource",
      "file lead source",
      "fileleadsource",
      "file-lead-source",
      "filesource",
      "file source",
      "file_source",
      "datasource",
      "data_source",
      "data source",
    ],
    campaign_id: [
      "campaignid",
      "campaign id",
      "campaign ID",
      "campaign_ID",
      "campaignID",
      "campaign",
      "campaignidentifier",
      "campaign_identifier",
      "campaign identifier",
    ],
  };

  const displayNames = {
    business_name: "Business Name",
    address: "Address",
    address2: "Address 2",
    city: "City",
    state_or_province: "State or Province",
    zip_or_postal_code: "ZIP or Postal Code",
    country: "Country",
    website: "Website",
    contact_first_name: "Contact First Name",
    contact_last_name: "Contact Last Name",
    contact_job_title: "Contact Job Title",
    contact_phone: "Contact Phone",
    contact_phone_extension: "Contact Phone Extension",
    contact_mobile: "Contact Mobile",
    contact_email: "Contact Email",
    contact_supplemental_email: "Contact Supplemental Email",
    contact_linkedin_url: "Contact LinkedIn URL",
    number_of_employees: "Number of Employees",
    products_of_interest: "Products of Interest",
    hq_source_provided: "HQ Source Provided",
    us_federal_agency: "US Federal Agency",
    lead_notes: "Lead Notes",
    custom_questions: "Custom Questions",
    asset_1: "Asset 1",
    asset_2: "Asset 2",
    asset_3: "Asset 3",
    file_lead_source: "File Lead Source",
    lead_source: "Lead Source",
    campaign_name: "Campaign Name",
    event_name: "Event Name",
    do_not_map: "Do Not Map",
  };

  const Review = ({ fileData, headerMapping }) => {
    return (
      <Box>
        <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
          Review Mapped Data
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: "1px solid #ddd",
              tableLayout: "auto",
              width: "100%",
            }}
          >
            <TableHead>
              <TableRow>
                {Object.values(headerMapping).map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      backgroundColor: "#cfe4fa",
                      padding: "10px 15px",
                      borderBottom: "2px solid #ddd",
                      borderBottom: "2px solid #ddd",
                      textAlign: "center",
                    }}
                  >
                    {displayNames[header] || header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fileData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(headerMapping).map((headerKey, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={{
                        padding: "10px",
                        borderBottom: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      {row[headerKey] || ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const getMappedHeader = (uploadedHeader) => {
    const normalizedHeader = uploadedHeader
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "");
    for (const [expectedHeader, variations] of Object.entries(
      headerVariations,
    )) {
      for (const variation of variations) {
        const normalizedVariation = variation
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "");
        if (normalizedHeader === normalizedVariation) {
          return expectedHeader;
        }
      }
    }
    for (const [expectedHeader, variations] of Object.entries(
      headerVariations,
    )) {
      for (const variation of variations) {
        const normalizedVariation = variation
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "");
        if (normalizedHeader.startsWith(normalizedVariation)) {
          return expectedHeader;
        }
      }
    }

    return "do_not_map";
  };

  const applyHeaderMapping = () => {
    const mappedData = fileData.map((row) => {
      const newRow = {};
      const leadNotesArray = [];
      const customQuestionsArray = [];

      Object.keys(row).forEach((header) => {
        const mappedHeader = headerMapping[header] || header;
        const value = String(row[header] || "");

        if (mappedHeader === "do_not_map") return;

        if (mappedHeader === "lead_notes") {
          if (value) {
            leadNotesArray.push(`"${value.replace(/"/g, '""')}"`);
          }
        } else if (mappedHeader === "custom_questions") {
          if (value) {
            customQuestionsArray.push(
              `"${formatHeader(header)}: ${value
                .replace(/,/g, "")
                .replace(/"/g, '""')}"`,
            );
          }
        } else {
          newRow[mappedHeader] = value;
        }
      });

      newRow["lead_notes"] =
        leadNotesArray.length > 0 ? leadNotesArray.join(", ") : "";
      newRow["custom_questions"] =
        customQuestionsArray.length > 0 ? customQuestionsArray.join(", ") : "";

      return newRow;
    });

    setFileData(mappedData);
    setHeaderMappingDialogOpen(false);
    setMappingsConfirmed(true);
  };

  const handleConfirmMappings = () => {
    setMappingsConfirmed(true);
    setConfirmationDialogOpen(false);
  };

  const handleCancelMapping = () => {
    setFileData([]);
    setFile(null);
    setHeaderMapping({});
    setUploadedHeaders([]);
    setConfirmationDialogOpen(false);
    setHeaderMappingDialogOpen(false);
    setMappingsConfirmed(false);
    toast.info("File removed successfully.");
  };

  const uploadChunk = async (chunk, chunkIndex, totalChunks, fileId) => {
    const chunkFormData = new FormData();
    const headers = Object.values(headerMapping).join(",");
    const rows = chunk
      .map((row) => {
        return Object.keys(headerMapping)
          .map((key) => `"${(row[key] || "").toString().replace(/"/g, '""')}"`)
          .join(",");
      })
      .join("\n");

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: "text/csv" });

    chunkFormData.append("file", blob, `chunk_${chunkIndex}.csv`);
    chunkFormData.append("file_id", fileId || "");
    chunkFormData.append("chunk_index", chunkIndex);
    chunkFormData.append("total_chunks", totalChunks);
    chunkFormData.append("created_by", auth.user_id);
    chunkFormData.append("campaign_name", campaignName);
    chunkFormData.append("file_lead_source", leadSource);
    chunkFormData.append("event_name", eventName);
    chunkFormData.append("file_name", file.name);

    if (chunkIndex === 0) {
      chunkFormData.append(
        "enrichment_fields",
        JSON.stringify(selectedEnrichmentFields),
      );

      const mappedFields = Object.values(headerMapping).filter(
      (header) => header !== "do_not_map"
    );
    chunkFormData.append("mapped_fields", JSON.stringify(mappedFields));
  }

    Object.keys(destinations).forEach((key) => {
      chunkFormData.append(key, destinations[key]);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/leads/uploadChunk`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          body: chunkFormData,
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Upload failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Upload chunk error:", error);
      throw error;
    }
  };

  const completeFileUpload = async (fileId) => {
    if (!fileId) {
      throw new Error("No file ID provided");
    }

    console.log("Completing file upload for fileId:", fileId);

    fetch(`${process.env.REACT_APP_API_URL}/leads/completeFileUpload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify({ file_id: fileId }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Complete file upload response:", data))
      .catch((error) => console.error("Error completing file upload:", error));
  };

  const handleSubmit = async () => {
    setIsUploading(true);
    setProgress(0);
    let fileId = null;

    try {
      const chunks = chunkArray(fileData, 100);

      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        const data = await uploadChunk(chunk, i, chunks.length, fileId);

        if (i === 0) {
          if (!data.file_id) {
            throw new Error("No file ID received from server");
          }
          fileId = data.file_id;
        }

        const progressValue = ((i + 1) / chunks.length) * 100;
        setProgress(progressValue);
      }

      if (fileId) {
        await completeFileUpload(fileId);
        setIsSubmitSuccess(true);
        setSuccessModalOpen(true);
      } else {
        throw new Error("No file ID available to complete upload");
      }
    } catch (error) {
      console.error("Upload error:", error);
      setErrorModalOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const isHeaderMappingComplete = () => {
    return Object.keys(headerMapping).every(
      (header) =>
        uploadedHeaders.includes(headerMapping[header]) ||
        headerMapping[header] === "do_not_map",
    );
  };

  const canProceedToNextStep = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    const stepName = currentSteps[activeStep];

    switch (stepName) {
      case "Set Up":
        return (
          destinations.send_to_nurture ||
          destinations.send_to_crm ||
          destinations.enrichment_only
        );
      case "File Upload":
        return !!file;
      case "Select Enrichment Fields":
        return (
          Array.isArray(selectedEnrichmentFields) &&
          selectedEnrichmentFields.length > 0
        );
      case "Map Fields":
        if (!needsHeaderMapping) return true;
        return isHeaderMappingComplete();
      case "Review":
        return true;
      case "Submit":
        return false;
      default:
        return false;
    }
  };

  const handleNext = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    if (activeStep === currentSteps.length - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    const currentSteps = getSteps(destinations.enrichment_only);
    const stepName = currentSteps[activeStep];

    if (stepName === "Review" && !needsHeaderMapping) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCloseModal = () => {
    history.push("/landing-page"); // Always navigate to landing page when modal closes
  };

  const renderConfirmationDialog = () => (
    <Dialog
      open={confirmationDialogOpen}
      onClose={() => setConfirmationDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Confirm Mappings</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please review the mappings below. If something is incorrect, you can
          remap the headers.
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Uploaded Header</TableCell>
              <TableCell>Mapped To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(headerMapping).map((uploadedHeader, index) => (
              <TableRow key={index}>
                <TableCell>{formatHeader(uploadedHeader)}</TableCell>
                <TableCell>
                  {formatHeader(headerMapping[uploadedHeader])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelMapping} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setHeaderMapping({});
            setConfirmationDialogOpen(false);
            setHeaderMappingDialogOpen(true);
          }}
          color="primary"
        >
          Remap
        </Button>
        <Button onClick={handleConfirmMappings} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getStepContent = (step) => {
    // Get total number of steps based on whether enrichment is selected
    const currentSteps = getSteps(destinations.enrichment_only);

    switch (currentSteps[step]) {
      case "Set Up":
        return (
          <SetUp
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            leadSource={leadSource}
            setLeadSource={setLeadSource}
            eventName={eventName}
            setEventName={setEventName}
            destinations={destinations}
            setDestinations={setDestinations}
          />
        );
      case "File Upload":
        return (
          <FileUpload
            file={file}
            setFile={setFile}
            setFileData={setFileData}
            expectedHeaders={expectedHeaders}
          />
        );
      case "Select Enrichment Fields":
        return (
          <EnrichmentFieldSelector
            onFieldsChange={(fields) => {
              setSelectedEnrichmentFields(fields);
            }}
          />
        );
      case "Map Fields":
        return (
          <MapFields
            fileData={fileData}
            headerMapping={headerMapping}
            setHeaderMapping={setHeaderMapping}
            expectedHeaders={expectedHeaders}
            setHeaderMappingDialogOpen={setHeaderMappingDialogOpen}
            headerMappingDialogOpen={headerMappingDialogOpen}
            uploadedHeaders={uploadedHeaders}
          />
        );
      case "Review":
        return <Review fileData={fileData} headerMapping={headerMapping} />;
      case "Submit":
        return (
          <Submit
            fileData={fileData}
            headerMapping={headerMapping}
            campaignName={campaignName}
            leadSource={leadSource}
            eventName={eventName}
            destinations={destinations}
            setProgress={setProgress}
            setIsSubmitSuccess={setIsSubmitSuccess}
            auth={auth}
            file={file}
            onSubmit={handleSubmit}
            isUploading={isUploading}
            selectedEnrichmentFields={selectedEnrichmentFields}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          zIndex: 10001,
          top: "80px",
          ".Toastify__toast": {
            backgroundColor: "white",
            opacity: "1 !important",
          },
        }}
      />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 800,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {getSteps(destinations.enrichment_only).map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 4, mb: 2, flexGrow: 1, overflow: "auto" }}>
            {activeStep === getSteps(destinations.enrichment_only).length ? (
              <Typography>All steps completed</Typography>
            ) : (
              <>{getStepContent(activeStep)}</>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              mt: 3,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {activeStep > 0 && (
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === getSteps(destinations.enrichment_only).length - 1 &&
              progress > 0 && (
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={24}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              )}
            {activeStep < getSteps(destinations.enrichment_only).length - 1 && (
              <Button
                onClick={handleNext}
                disabled={!canProceedToNextStep()}
                variant="contained"
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ gap: 1 }}
          >
            <Typography variant="h6" component="div">
              Success
            </Typography>
            <CheckCircleIcon sx={{ color: "#4caf50", fontSize: 30 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center" mt={2}>
            <Typography
              variant="body1"
              sx={{ fontSize: "1.1rem", color: "#333" }}
            >
              Your file has been successfully uploaded! Processing will complete
              in the background.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mt: 2, mb: 1 }}>
          <Button
            onClick={() => {
              setSuccessModalOpen(false);
              history.push("/landing-page");
            }}
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              padding: "8px 20px",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {renderConfirmationDialog()}
    </>
  );
};

export default UploadList;
