import React, { useState, useEffect, useContext } from "react";
import Card from "../shared/components/UIElements/Card";
import "./ReportingDashboard.css";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from '../shared/context/auth-context';

const neutralColors = [
  "rgba(255, 159, 64, 0.2)", // light orange
  "rgba(153, 102, 255, 0.2)", // light purple
  "rgba(75, 192, 192, 0.2)", // light teal
  "rgba(54, 162, 235, 0.2)", // light blue
  "rgba(201, 203, 207, 0.2)", // light grey
  "rgba(255, 205, 86, 0.2)", // light yellow
  "rgba(230, 230, 250, 0.2)", // lavender
  "rgba(210, 180, 140, 0.2)", // tan
  "rgba(244, 164, 96, 0.2)", // sandy brown
  "rgba(176, 224, 230, 0.2)", // powder blue
  "rgba(173, 216, 230, 0.2)", // light blue
  "rgba(221, 160, 221, 0.2)", // plum
  "rgba(240, 128, 128, 0.2)", // light coral
  "rgba(250, 235, 215, 0.2)", // antique white
  "rgba(220, 220, 220, 0.2)", // gainsboro
  "rgba(255, 218, 185, 0.2)", // peach puff
  "rgba(144, 238, 144, 0.2)", // light green
  "rgba(255, 222, 173, 0.2)", // navajo white
  "rgba(245, 245, 245, 0.2)", // white smoke
  "rgba(211, 211, 211, 0.2)", // light grey
];

const neutralBorderColors = [
  "rgba(255, 159, 64, 1)", // orange
  "rgba(153, 102, 255, 1)", // purple
  "rgba(75, 192, 192, 1)", // teal
  "rgba(54, 162, 235, 1)", // blue
  "rgba(201, 203, 207, 1)", // grey
  "rgba(255, 205, 86, 1)", // yellow
  "rgba(230, 230, 250, 1)", // lavender
  "rgba(210, 180, 140, 1)", // tan
  "rgba(244, 164, 96, 1)", // sandy brown
  "rgba(176, 224, 230, 1)", // powder blue
  "rgba(173, 216, 230, 1)", // light blue
  "rgba(221, 160, 221, 1)", // plum
  "rgba(240, 128, 128, 1)", // light coral
  "rgba(250, 235, 215, 1)", // antique white
  "rgba(220, 220, 220, 1)", // gainsboro
  "rgba(255, 218, 185, 1)", // peach puff
  "rgba(144, 238, 144, 1)", // light green
  "rgba(255, 222, 173, 1)", // navajo white
  "rgba(245, 245, 245, 1)", // white smoke
  "rgba(211, 211, 211, 1)", // light grey
];

const ReportingDashboard = () => {
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [crmData, setCrmData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("last_7_days");

  const {
    isLoading: isBarLoading,
    error: barError,
    sendRequest: sendBarRequest,
  } = useHttpClient();
  const {
    isLoading: isLineLoading,
    error: lineError,
    sendRequest: sendLineRequest,
  } = useHttpClient();
  const {
    isLoading: isCrmLoading,
    error: crmError,
    sendRequest: sendCrmRequest,
  } = useHttpClient();

  const {
    sendRequest: sendTrackingRequest,
  } = useHttpClient();

  const auth = useContext(AuthContext);

  const trackPageVisit = async (pageName) => {
    try {
      await sendTrackingRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("reporting_dashboard");
    }
  }, [auth]);

  useEffect(() => {
    const fetchBarData = async () => {
      try {
        const result = await sendBarRequest(
          `${process.env.REACT_APP_API_URL}/leads/reports/lead_volume`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setBarData(result);
      } catch (error) {
        console.error("Error fetching lead volume data:", error);
      }
    };

    if (auth && auth.token) {
      fetchBarData();
    }
  }, [sendBarRequest, auth]);

  useEffect(() => {
    const fetchLineData = async () => {
      try {
        const result = await sendLineRequest(
          `${process.env.REACT_APP_API_URL}/leads/reports/referral_url_volume`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setLineData(result);
      } catch (error) {
        console.error("Error fetching referral volume data:", error);
      }
    };

    if (auth && auth.token) {
      fetchLineData();
    }
  }, [sendLineRequest, auth]);

  useEffect(() => {
    const fetchCrmData = async () => {
      try {
        const result = await sendCrmRequest(
          `${process.env.REACT_APP_API_URL}/leads/reports/crm_volume`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setCrmData(result);
      } catch (error) {
        console.error("Error fetching CRM data:", error);
      }
    };

    if (auth && auth.token) {
      fetchCrmData();
    }
  }, [sendCrmRequest, auth]);

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const filterBarData = () => {
    if (!Array.isArray(barData) || barData.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            label: "Lead Volume",
            data: [],
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      };
    }

    const labels = Array.from(new Set(barData.map((item) => item.lead_source)));
    const divisions = Array.from(new Set(barData.map((item) => item.division)));

    const datasets = divisions.map((division, index) => {
      const data = labels.map((label) => {
        const item = barData.find(
          (item) => item.lead_source === label && item.division === division,
        );
        return item ? item[selectedPeriod] : 0;
      });
      return {
        label: division,
        data: data,
        backgroundColor: neutralColors[index % neutralColors.length],
        borderColor: neutralBorderColors[index % neutralBorderColors.length],
        borderWidth: 1,
      };
    });

    return {
      labels: labels,
      datasets: datasets,
    };
  };

  const processCrmDoughnutChartData = () => {
    if (!Array.isArray(crmData) || crmData.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };
    }

    const labels = crmData.map((item) => item.crm);
    const data = crmData.map((item) => item.leads);
    const backgroundColor = labels.map(
      (_, index) => neutralColors[index % neutralColors.length],
    );
    const borderColor = labels.map(
      (_, index) => neutralBorderColors[index % neutralBorderColors.length],
    );

    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };
  };

  const processLineChartData = () => {
    if (!Array.isArray(lineData) || lineData.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const dates = new Set();
    const datasets = [];

    lineData.forEach((dataset) => {
      const dataPoints = [];
      Object.keys(dataset).forEach((key) => {
        if (key !== "referring_url" && key !== "lead_volume") {
          dates.add(key);
          dataPoints.push({ x: key, y: dataset[key] });
        }
      });
      datasets.push({
        label: dataset.referring_url || "No URL",
        data: dataPoints.sort((a, b) => new Date(a.x) - new Date(b.x)),
      });
    });

    return {
      labels: Array.from(dates).sort((a, b) => new Date(a) - new Date(b)),
      datasets: datasets.map((set, index) => ({
        label: set.label,
        data: set.data,
        fill: false,
        backgroundColor: neutralColors[index % neutralColors.length],
        borderColor: neutralBorderColors[index % neutralBorderColors.length],
      })),
    };
  };

  return (
    <div className="reporting-dashboard">
      {/* Highlight Cards Section */}
      <div className="highlight-cards">
        <Card className="highlight-card">
          <h3>Leads Submitted</h3>
          <div className="card-metric">
            <span className="main-value">442,236</span>
            <div className="percentage-change increase">
              <span className="arrow">↑</span> 59.3%
            </div>
          </div>
          <p className="small-text">Lead volume is up 35,000 this month</p>
        </Card>
        <Card className="highlight-card">
          <h3>Leads Converted</h3>
          <div className="card-metric">
            <span className="main-value">78,250</span>
            <div className="percentage-change increase">
              <span className="arrow">↑</span> 70.5%
            </div>
          </div>
          <p className="small-text">Conversions have increased by 8,900 this month</p>
        </Card>
        <Card className="highlight-card">
          <h3>Leads Archived</h3>
          <div className="card-metric">
            <span className="main-value">18,800</span>
            <div className="percentage-change decrease">
              <span className="arrow">↓</span> 27.4%
            </div>
          </div>
          <p className="small-text">Archived leads are down by 1,200 this month</p>
        </Card>
        <Card className="highlight-card">
          <h3>Sales Generated</h3>
          <div className="card-metric">
            <span className="main-value">$35,078</span>
            <div className="percentage-change decrease">
              <span className="arrow">↓</span> 27.4%
            </div>
          </div>
          <p className="small-text">Sales are down $20,395 this month</p>
        </Card>
      </div>
      {/* Graphs Section */}
      <div className="graphs">
        <Card className="bar-card">
          <h2 className="card-title">Lead Volume</h2>
          <div className="card-content">
            <div className="filter-boxes">
              <button onClick={() => handlePeriodChange("last_7_days")}>
                Last 7 Days
              </button>
              <button onClick={() => handlePeriodChange("last_30_days")}>
                Last 30 Days
              </button>
              <button onClick={() => handlePeriodChange("last_1_year")}>
                Last 1 Year
              </button>
              <button onClick={() => handlePeriodChange("all_time")}>
                All Time
              </button>
            </div>
            <div className="bar-graph-container">
              {isBarLoading ? (
                <div className="center">
                  <LoadingSpinner />
                </div>
              ) : barError ? (
                <p>{barError}</p>
              ) : (
                <Bar
                  data={filterBarData()}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                    plugins: {
                      legend: {
                        position: "top",
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </Card>

        <Card className="crm-card">
          <h2 className="card-title">Leads Written to CRMs</h2>
          <div className="card-content">
            <div className="crm-graph-container">
              {isCrmLoading ? (
                <div className="center">
                  <LoadingSpinner />
                </div>
              ) : crmError ? (
                <p>{crmError}</p>
              ) : (
                <Doughnut
                  data={processCrmDoughnutChartData()}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </Card>

        <Card className="line-card">
          <h2 className="card-title">Leads by Referring URL</h2>
          <div className="card-content">
            <div className="line-graph-container">
              {isLineLoading ? (
                <div className="center">
                  <LoadingSpinner />
                </div>
              ) : lineError ? (
                <p>{lineError}</p>
              ) : (
                <Line
                  data={processLineChartData()}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "left",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ReportingDashboard;
