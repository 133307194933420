import React from 'react';
import { Box, TextField, FormControlLabel, Checkbox, Typography, Tooltip } from '@mui/material';

const SetUp = ({
  campaignName,
  setCampaignName,
  leadSource,
  setLeadSource,
  eventName,
  setEventName,
  destinations,
  setDestinations,
}) => {
  const handleCheckboxChange = (event) => {
    setDestinations({
      ...destinations,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Set Up</Typography>
      <TextField
        fullWidth
        label="Campaign Name"
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Lead Source"
        value={leadSource}
        onChange={(e) => setLeadSource(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Event Name"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
        margin="normal"
      />
      <Typography variant="subtitle1" gutterBottom>Lead Destinations:</Typography>
      <Tooltip title="Coming soon!" placement="right">
        <span> {/* Wrapper needed for disabled FormControlLabel */}
          <FormControlLabel
            control={
              <Checkbox
                checked={destinations.send_to_nurture}
                onChange={handleCheckboxChange}
                name="send_to_nurture"
                disabled
              />
            }
            label="Nurture"
            sx={{ 
              color: 'text.disabled',
              '.MuiFormControlLabel-label': {
                color: 'text.disabled'
              }
            }}
          />
        </span>
      </Tooltip>
      <Tooltip title="Coming soon!" placement="right">
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={destinations.send_to_crm}
                onChange={handleCheckboxChange}
                name="send_to_crm"
                disabled
              />
            }
            label="CRM"
            sx={{ 
              color: 'text.disabled',
              '.MuiFormControlLabel-label': {
                color: 'text.disabled'
              }
            }}
          />
        </span>
      </Tooltip>
      <FormControlLabel
        control={
          <Checkbox
            checked={destinations.enrichment_only}
            onChange={handleCheckboxChange}
            name="enrichment_only"
          />
        }
        label="Enrichment Only"
      />
    </Box>
  );
};

export default SetUp;
