import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { 
  Star, 
  ArrowUp, 
  ArrowDown, 
  ChevronDown,
  ChevronRight, 
  Search 
} from 'lucide-react';
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

const MetricCard = ({ label, value, trending, suffix = '' }) => (
  <div>
    <div className="text-sm text-gray-500">{label}</div>
    <div className="flex items-center">
      <span className="text-lg font-semibold text-gray-900">
        {value}{suffix}
      </span>
      {typeof trending === 'number' && trending !== 0 && (
        <span className={`ml-2 flex items-center text-sm ${
          trending >= 0 ? 'text-green-600' : 'text-red-600'
        }`}>
          {trending >= 0 ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
          {Math.abs(trending)}%
        </span>
      )}
    </div>
  </div>
);

const CampaignCard = ({ campaign, isExpanded, onToggleExpand }) => {
  // Directly destructure metrics from campaign with default values
  const {
    leads = { total: 0, mql: 0, sql: 0, trending: 0 },
    opportunities = { total: 0, trending: 0 },
    closed = { winRate: 0, value: 0 },
    engagement = { emailOpenRate: 0 }
  } = campaign.metrics || {};

  const formatValue = (value) => {
    if (typeof value !== 'number') return '0';
    return value.toLocaleString();
  };

  const formatCurrency = (value) => {
    if (typeof value !== 'number') return '$0.0M';
    return `$${(value / 1000000).toFixed(1)}M`;
  };

  return (
    <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
      <div 
        className="p-6 hover:bg-gray-50 cursor-pointer"
        onClick={() => onToggleExpand(campaign.campaign_id)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                // Toggle follow status
              }}
              className={`text-yellow-400 hover:text-yellow-500 ${
                campaign.isFollowed ? 'fill-current' : ''
              }`}
            >
              <Star className="w-5 h-5" />
            </button>
            <div>
              <h3 className="text-lg font-medium text-gray-900">{campaign.name}</h3>
              <div className="flex items-center space-x-3 mt-1">
                <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  campaign.status === 'active' 
                    ? 'bg-green-100 text-green-800'
                    : campaign.status === 'completed'
                    ? 'bg-orange-100 text-orange-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {campaign.status}
                </span>
                <span className="text-sm text-gray-500">{campaign.dateRange}</span>
                <span className="text-sm text-gray-500">Owner: {campaign.owner}</span>
                <span className="text-sm text-gray-500 bg-blue-50 px-2 py-1 rounded">
                  {campaign.type}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-8">
            <div className="grid grid-cols-4 gap-8">
              <MetricCard 
                label="Leads" 
                value={formatValue(leads.total)}
                trending={leads.trending}
              />
              <MetricCard 
                label="Opportunities" 
                value={formatValue(opportunities.total)}
                trending={opportunities.trending}
              />
              <MetricCard 
                label="Win Rate" 
                value={formatValue(closed.winRate)}
                suffix="%"
              />
              <MetricCard 
                label="Engagement" 
                value={formatValue(engagement.emailOpenRate)}
                suffix="%"
              />
            </div>
            
            <button>
              {isExpanded ? (
                <ChevronDown className="w-5 h-5 text-gray-400" />
              ) : (
                <ChevronRight className="w-5 h-5 text-gray-400" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="border-t border-gray-200 p-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Pipeline Performance</h4>
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-blue-50 rounded-lg p-4">
                    <p className="text-sm text-blue-700">MQLs</p>
                    <p className="text-lg font-semibold text-blue-900">
                      {formatValue(leads.mql)}
                    </p>
                  </div>
                  <div className="bg-purple-50 rounded-lg p-4">
                    <p className="text-sm text-purple-700">SQLs</p>
                    <p className="text-lg font-semibold text-purple-900">
                      {formatValue(leads.sql)}
                    </p>
                  </div>
                  <div className="bg-green-50 rounded-lg p-4">
                    <p className="text-sm text-green-700">Closed</p>
                    <p className="text-lg font-semibold text-green-900">
                      {formatCurrency(closed.value)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Performance Trend</h4>
              <div className="h-48">
                {campaign.trend && campaign.trend.length > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={campaign.trend}>
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Line 
                        type="monotone" 
                        dataKey="value" 
                        stroke="#3B82F6" 
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-500">
                    No trend data available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Campaigns = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedCampaigns, setLoadedCampaigns] = useState([]);
  const [expandedCampaign, setExpandedCampaign] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/campaigns/all`,
          'GET',
          null,
          {
            Authorization: `Bearer ${auth.token}`
          }
        );
        setLoadedCampaigns(responseData);
      } catch (err) {
        console.error('Error fetching campaigns:', err);
      }
    };

    if (auth && auth.token) {
      fetchCampaigns();
    }
  }, [sendRequest, auth]);

  useEffect(() => {
    const trackPageVisit = async () => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/track-page-visit`,
          'POST',
          JSON.stringify({
            user_id: auth.user_id,
            page_name: 'campaigns'
          }),
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        );
      } catch (error) {
        console.error('Error tracking page visit:', error);
      }
    };

    if (auth && auth.user_id) {
      trackPageVisit();
    }
  }, [auth, sendRequest]);

  const filteredCampaigns = loadedCampaigns
    .filter(campaign => 
      (selectedStatus === 'all' || campaign.status === selectedStatus) &&
      (campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       campaign.owner.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  return (
    <div className="campaigns-page-container min-h-screen bg-gray-50">
      <ErrorModal error={error} onClear={clearError} />
      
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900">Campaign Performance</h1>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search campaigns..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm"
                />
                <Search className="w-4 h-4 text-gray-400 absolute left-3 top-3" />
              </div>

              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="border border-gray-300 rounded-lg text-sm px-4 py-2"
              >
                <option value="all">All Campaigns</option>
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="draft">Draft</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {isLoading && (
          <div className="flex justify-center items-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && filteredCampaigns && (
          <div className="space-y-4">
            {filteredCampaigns.map(campaign => (
              <CampaignCard
                key={campaign.campaign_id}
                campaign={campaign}
                isExpanded={expandedCampaign === campaign.campaign_id}
                onToggleExpand={(id) => setExpandedCampaign(expandedCampaign === id ? null : id)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Campaigns;
