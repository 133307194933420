export const ProductOptions = [
      { value: 'advanced security', label: 'Advanced Security'},
      { value: 'advanced voice - sip', label: 'Advanced Voice - SIP'},
      { value: 'advanced voice - pri', label: 'Advanced Voice - PRI'},
      { value: 'business internet', label: 'Business Internet'},
      { value: 'business television - private view', label: 'Business Television - Private View'},
      { value: 'business television - public view', label: 'Business Television - Public View'},
      { value: 'business voice', label: 'Business Voice'},
      { value: 'businessvoiceedge', label: 'BusinessVoiceEdge'},
      { value: 'cloud solutions', label: 'Cloud Solutions'},
      { value: 'connection pro', label: 'Connection Pro'},
      { value: 'corporate network access', label: 'Corporate Network Access'},
      { value: 'ddos mitigation service', label: 'DDoS Mitigation Service'},
      { value: 'deep blue wifi', label: 'Deep Blue WiFi'},
      { value: 'ethernet', label: 'Ethernet'},
      { value: 'managed connectivity', label: 'Managed Connectivity'},
      { value: 'managed router', label: 'Managed Router'},
      { value: 'managed wireless lan', label: 'Managed Wireless LAN'},
      { value: 'professional services', label: 'Professional Services'},
      { value: 'sd-wan', label: 'SD-WAN'},
      { value: 'secure remote access', label: 'Secure Remote Access'},
      { value: 'securityedge', label: 'SecurityEdge'},
      { value: 'wavelength services', label: 'Wavelength Services'},
      { value: 'wifi pro', label: 'WiFi Pro'},
      { value: 'wireless backup lte', label: 'Wireless Backup LTE'}
  ];