import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

const Submit = ({ 
  onSubmit, 
  isUploading 
}) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Submit</Typography>
      <Typography variant="body1" gutterBottom>
        Please review all information before submitting. Once submitted, the file will be processed and enriched.
      </Typography>
      {isUploading ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={24} sx={{ mr: 2 }} />
          <Typography>Uploading...</Typography>
        </Box>
      ) : (
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Submit
        </Button>
      )}
    </Box>
  );
};

export default Submit;
