import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Portal from "@mui/material/Portal";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from '../../shared/context/auth-context';
import "./UserLeads.css";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#ffffff"
        },
        columnHeaders: {
          backgroundColor: "#f5f5f5",
          color: "#000",
          fontWeight: "bold"
        },
        columnHeaderTitle: {
          fontSize: "16px"
        },
        cell: {
          borderBottom: "1px solid #ddd"
        },
        row: {
          "&:hover": {
            backgroundColor: "#e3f2fd"
          }
        },
        footerContainer: {
          backgroundColor: "#f1f3f4"
        }
      }
    }
  }
});

// Function to format the date
const formatDate = (dateString, timeZone) => {
  try {
    if (!dateString || typeof dateString !== "string") {
      return "Invalid Date";
    }

    // Convert to ISO 8601 format
    const isoString = dateString.replace(' ', 'T') + 'Z';
    const date = new Date(isoString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options = {
      timeZone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);

  } catch (error) {
    return "Invalid Date";
  }  
};

// Custom Pagination Component
const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, (state) => state.pagination.page);
  const pageCount = useGridSelector(apiRef, (state) => state.pagination.pageCount);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

// Custom Toolbar Component
const CustomToolbar = (props) => {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById('filter-panel')}>
        <GridToolbarQuickFilter />
      </Portal>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    </React.Fragment>
  );
};

const UserLeads = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user_id = useParams().user_id;

  const auth = useContext(AuthContext);

  const userTimeZone = localStorage.getItem("time_zone") || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const trackPageVisit = async (pageName) => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + "/users/track-page-visit",
        "POST",
        JSON.stringify({
          user_id: auth.user_id,
          page_name: pageName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );
    } catch (error) {
      console.error("Error tracking page visit:", error);
    }
  };

  useEffect(() => {
    if (auth && auth.user_id) {
      trackPageVisit("user_leads");
    }
  }, [auth]);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/leads/user/${user_id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setLeads(responseData);
      } catch (err) {
        console.error("Error fetching leads:", err);
      }
      setIsLoading(false);
    };

    if (auth && auth.token) {
      fetchLeads();
    }
  }, [sendRequest, user_id, auth]);

  const columns = [
    { field: "created_date", headerName: "Created Date", width: 200, valueFormatter: (params) => formatDate(params.value, userTimeZone) },
    { field: "crm_response", headerName: "CRM Response", width: 550 },
    { field: "referral_url", headerName: "Referral URL", width: 550 },
    { field: "business_hq_immediate", headerName: "Business HQ Immediate", width: 200 },
    { field: "immediate_parent_name", headerName: "Immediate Parent Name", width: 300 },
    { field: "hq_division", headerName: "HQ Division", width: 150 },
    { field: "business_id", headerName: "Business ID", width: 150 },
    { field: "business_name", headerName: "Business Name", width: 300 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state_or_province", headerName: "State/Province", width: 150 },
    { field: "zip_or_postal_code", headerName: "Zip/Postal Code", width: 150 },
    { field: "country", headerName: "Country", width: 150 },
    { field: "cmcst_division", headerName: "Division", width: 200 },
    { field: "cmcst_region", headerName: "Region", width: 250 },
    { field: "mktg_segment", headerName: "Segment", width: 200 },
    { field: "mktg_subsegment", headerName: "Subsegment", width: 250 },
    { field: "industry", headerName: "Industry", width: 400 },
    { field: "sub_industry", headerName: "Sub-Industry", width: 400 },
    { field: "location_headcount", headerName: "Location Headcount", width: 200 },
    { field: "cmcst_status_cb", headerName: "Customer Status", width: 150 },
    { field: "website", headerName: "Website", width: 300 },
    { field: "contact_id", headerName: "Contact ID", width: 150 },
    { field: "contact_first_name", headerName: "First Name", width: 180 },
    { field: "contact_last_name", headerName: "Last Name", width: 180 },
    { field: "contact_job_title", headerName: "Job Title", width: 350 },
    { field: "contact_phone", headerName: "Phone", width: 150 },
    { field: "contact_mobile", headerName: "Mobile", width: 150 },
    { field: "contact_email", headerName: "Email", width: 350 },
    { field: "products_of_interest", headerName: "Products of Interest", width: 250 },
    { field: "campaign_id", headerName: "Campaign ID", width: 300 },
  ];

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && leads && (
        <ThemeProvider theme={theme}>
          <div className="user-leads-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box id="filter-panel" />
              </Grid>
              <Grid item xs={12}>
                <div className="user-leads-grid">
                  <DataGrid
                    rows={leads}
                    columns={columns}
                    getRowId={(row) => row.lead_id}
                    pageSize={100}
                    pagination
                    components={{
                      Toolbar: CustomToolbar,
                      Pagination: CustomPagination,
                    }}
                    disableSelectionOnClick
                    density="compact"
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterExcludeHiddenColumns: true,
                        },
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default UserLeads;
