import React, { useState, useEffect, useContext } from "react";
import {
  Calendar as CalendarIcon,
  Plus,
  ChevronLeft,
  ChevronRight,
  Clock,
  Users,
  X,
  Loader2,
} from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const getEventColor = (type) => {
  switch (type) {
    case "meeting":
      return "bg-blue-100 text-blue-700";
    case "deadline":
      return "bg-purple-100 text-purple-700";
    case "conference":
      return "bg-green-100 text-green-700";
    case "webinar":
      return "bg-orange-100 text-orange-700";
    case "review":
      return "bg-pink-100 text-pink-700";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

const getEventBgColor = (type) => {
  switch (type) {
    case "meeting":
      return "bg-blue-50";
    case "deadline":
      return "bg-purple-50";
    case "conference":
      return "bg-green-50";
    case "webinar":
      return "bg-orange-50";
    case "review":
      return "bg-pink-50";
    default:
      return "bg-gray-50";
  }
};

const getEventIconColor = (type) => {
  switch (type) {
    case "meeting":
      return "text-blue-600";
    case "deadline":
      return "text-purple-600";
    case "conference":
      return "text-green-600";
    case "webinar":
      return "text-orange-600";
    case "review":
      return "text-pink-600";
    default:
      return "text-gray-600";
  }
};

const AddEventForm = ({ isOpen, onClose, onEventAdded, teamMembers, auth }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [formData, setFormData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    time: "",
    isMultiDay: false,
    type: "meeting",
    description: "",
    attendees: [],
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const eventData = {
      ...formData,
      time: formData.isMultiDay ? null : formData.time,
    };

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/events/create`,
        "POST",
        JSON.stringify(eventData),
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      );

      onEventAdded(response);
      onClose();
      setFormData({
        title: "",
        startDate: "",
        endDate: "",
        time: "",
        isMultiDay: false,
        type: "meeting",
        description: "",
        attendees: [],
      });
    } catch (err) {
      setError(err.message || "Failed to create event");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAttendeeChange = (userId) => {
    setFormData((prev) => ({
      ...prev,
      attendees: prev.attendees.includes(userId)
        ? prev.attendees.filter((id) => id !== userId)
        : [...prev.attendees, userId],
    }));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <div className="flex items-center justify-between mb-4">
            <DialogTitle>Add New Event</DialogTitle>
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100"
            >
              <X className="h-4 w-4 text-gray-500" />
            </button>
          </div>
        </DialogHeader>

        {error && (
          <div className="mb-4 p-3 rounded-md bg-red-50 border border-red-200 text-sm text-red-800">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Event Title
            </label>
            <input
              type="text"
              name="title"
              required
              value={formData.title}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md text-sm"
              placeholder="Enter event title"
            />
          </div>

          <div className="space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="isMultiDay"
                checked={formData.isMultiDay}
                onChange={handleInputChange}
                className="h-4 w-4 text-blue-600 rounded border-gray-300"
              />
              <span className="text-sm font-medium text-gray-700">
                Multi-day event
              </span>
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {formData.isMultiDay ? "Start Date" : "Date"}
              </label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                <input
                  type="date"
                  name="startDate"
                  required
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="w-full pl-10 pr-3 py-2 border rounded-md text-sm"
                />
              </div>
            </div>
            {formData.isMultiDay ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <div className="relative">
                  <CalendarIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  <input
                    type="date"
                    name="endDate"
                    required
                    value={formData.endDate}
                    onChange={handleInputChange}
                    className="w-full pl-10 pr-3 py-2 border rounded-md text-sm"
                    min={formData.startDate}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Time
                </label>
                <div className="relative">
                  <Clock className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  <input
                    type="time"
                    name="time"
                    required
                    value={formData.time}
                    onChange={handleInputChange}
                    className="w-full pl-10 pr-3 py-2 border rounded-md text-sm"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Event Type
            </label>
            <select
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md text-sm bg-white"
            >
              <option value="meeting">Meeting</option>
              <option value="conference">Conference</option>
              <option value="webinar">Webinar</option>
              <option value="deadline">Deadline</option>
              <option value="review">Review</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={3}
              className="w-full px-3 py-2 border rounded-md text-sm"
              placeholder="Add event description..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Attendees
            </label>
            <div className="border rounded-md divide-y max-h-48 overflow-y-auto">
              {teamMembers.map((member) => (
                <label
                  key={member.user_id}
                  className="flex items-center p-3 hover:bg-gray-50 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 rounded border-gray-300"
                    checked={formData.attendees.includes(member.user_id)}
                    onChange={() => handleAttendeeChange(member.user_id)}
                  />
                  <div className="ml-3 flex items-center">
                    <img
                      src={member.avatar}
                      alt=""
                      className="w-6 h-6 rounded-full"
                    />
                    <span className="ml-2 text-sm text-gray-900">
                      {member.username}
                    </span>
                  </div>
                </label>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Creating...
                </>
              ) : (
                "Create Event"
              )}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const TeamCalendarModal = ({ isOpen, onClose, teamMembers }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddEventForm, setShowAddEventForm] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const fetchEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const eventPromises = teamMembers.map((member) =>
        sendRequest(
          `${process.env.REACT_APP_API_URL}/events/user/${member.user_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        ).catch((err) => {
          console.error(
            `Error fetching events for user ${member.user_id}:`,
            err,
          );
          return [];
        }),
      );

      const allUserEvents = await Promise.all(eventPromises);

      const eventMap = new Map();
      allUserEvents.flat().forEach((event) => {
        if (!eventMap.has(event.event_id)) {
          eventMap.set(event.event_id, {
            id: event.event_id,
            title: event.title,
            startDate: event.startDate,
            endDate: event.endDate || event.startDate,
            time: event.time,
            type: event.type,
            attendees: event.attendees || [],
            owner: event.owner,
            description: event.description,
            created_by: event.created_by,
          });
        }
      });

      const formattedEvents = Array.from(eventMap.values()).map((event) => ({
        ...event,
        ownerName: getMemberName(event.created_by),
        attendeeNames: event.attendees
          .map((attendeeId) => getMemberName(attendeeId))
          .filter((name) => name !== "Unknown User"),
      }));

      setEvents(formattedEvents);
    } catch (err) {
      setError(err.message || "Failed to fetch team events");
    }
    setIsLoading(false);
  };

  const getMemberName = (userId) => {
    const member = teamMembers.find((m) => m.user_id === userId);
    return member ? member.username : "Unknown User";
  };

  useEffect(() => {
    if (isOpen && auth.token && teamMembers?.length > 0) {
      fetchEvents();
    }
  }, [isOpen, auth.token, teamMembers]);

  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1,
  );
  const lastDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0,
  );

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1),
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1),
    );
  };

  const getDaysInMonth = () => {
    const daysArray = [];
    const startDay = firstDayOfMonth.getDay();

    for (let i = 0; i < startDay; i++) {
      daysArray.push(null);
    }

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysArray.push(i);
    }

    return daysArray;
  };

  const getEventsByDay = (day) => {
    if (!day) return [];

    // Create date object for the current day being checked
    const currentDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day,
    );

    // Set time to start of day for proper comparison
    currentDate.setHours(0, 0, 0, 0);

    return events.filter((event) => {
      // Create date objects for start and end dates
      const startDate = new Date(event.startDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = event.endDate
        ? new Date(event.endDate)
        : new Date(event.startDate);
      endDate.setHours(23, 59, 59, 999);

      // Check if current date falls within the event's date range
      return currentDate >= startDate && currentDate <= endDate;
    });
  };

  const getUpcomingEvents = () => {
    const today = new Date();
    return events
      .filter((event) => {
        const eventStartDate = new Date(
          `${event.startDate}${event.time ? ` ${event.time}` : ""}`,
        );
        return eventStartDate >= today;
      })
      .sort((a, b) => {
        const dateA = new Date(`${a.startDate}${a.time ? ` ${a.time}` : ""}`);
        const dateB = new Date(`${b.startDate}${b.time ? ` ${b.time}` : ""}`);
        return dateA - dateB;
      })
      .slice(0, 5);
  };

  const handleEventAdded = (newEvent) => {
    fetchEvents();
  };

  const handleDeleteEvent = async () => {
    if (!deleteEvent) return;

    setIsDeleting(true);
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/events/${deleteEvent.id}`,
        "DELETE",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
      );

      setEvents(events.filter((e) => e.id !== deleteEvent.id));
      setDeleteEvent(null);
    } catch (err) {
      setError(err.message || "Failed to delete event");
    }
    setIsDeleting(false);
  };

  const renderEvent = (event) => {
    const isMultiDay = event.endDate && event.endDate !== event.startDate;
    return (
      <div
        key={event.id}
        className={`text-xs px-1.5 py-1 rounded-sm truncate cursor-pointer hover:opacity-80 ${getEventColor(event.type)}`}
        title={`${event.title}${event.time ? ` - ${event.time}` : ""}${
          event.ownerName ? ` - Created by ${event.ownerName}` : ""
        }${isMultiDay ? " (Multi-day event)" : ""}`}
      >
        {event.time ? `${event.time.substring(0, 5)} ` : ""}
        {event.title}
        {isMultiDay && " (Multi-day)"}
      </div>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[600px] max-h-[85vh] overflow-y-auto pt-8">
          <DialogHeader className="mb-4">
            <div className="flex items-center justify-between">
              <DialogTitle>Team Calendar</DialogTitle>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <button
                    onClick={handlePrevMonth}
                    className="p-1 hover:bg-gray-100 rounded-full"
                  >
                    <ChevronLeft className="h-4 w-4" />
                  </button>
                  <span className="text-sm font-medium">
                    {currentMonth.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={handleNextMonth}
                    className="p-1 hover:bg-gray-100 rounded-full"
                  >
                    <ChevronRight className="h-4 w-4" />
                  </button>
                </div>
                <button
                  onClick={() => setShowAddEventForm(true)}
                  className="flex items-center space-x-1 px-2 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md"
                >
                  <Plus className="h-4 w-4" />
                  <span>Add Event</span>
                </button>
              </div>
            </div>
          </DialogHeader>

          {error && (
            <div className="mb-4 p-3 rounded-md bg-red-50 border border-red-200 text-sm text-red-800">
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="flex items-center justify-center h-40">
              <div className="text-sm text-gray-500">
                Loading team events...
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-6">
              <div className="grid grid-cols-7 gap-px bg-gray-200 border rounded-lg">
                {daysOfWeek.map((day) => (
                  <div
                    key={day}
                    className="bg-white p-2 text-center text-xs font-medium text-gray-600"
                  >
                    {day}
                  </div>
                ))}
                {getDaysInMonth().map((day, index) => {
                  const isToday =
                    day === currentDate.getDate() &&
                    currentMonth.getMonth() === currentDate.getMonth() &&
                    currentMonth.getFullYear() === currentDate.getFullYear();
                  const dayEvents = getEventsByDay(day);

                  return (
                    <div
                      key={index}
                      className={`bg-white p-2 min-h-[80px] relative ${
                        isToday ? "bg-blue-50" : ""
                      }`}
                    >
                      {day && (
                        <>
                          <span
                            className={`text-xs ${
                              isToday
                                ? "text-blue-600 font-medium"
                                : "text-gray-600"
                            }`}
                          >
                            {day}
                          </span>
                          <div className="mt-1 space-y-1">
                            {dayEvents.map(renderEvent)}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>

              <div>
                <h3 className="text-sm font-medium mb-3">Upcoming Events</h3>
                <div className="space-y-3">
                  {getUpcomingEvents().map((event) => (
                    <div
                      key={event.id}
                      className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg relative"
                    >
                      {auth.user_id === event.created_by && (
                        <button
                          onClick={() => setDeleteEvent(event)}
                          className="absolute top-2 right-2 p-1 hover:bg-gray-200 rounded-full"
                          title="Delete event"
                        >
                          <X className="h-4 w-4 text-gray-500" />
                        </button>
                      )}
                      <div
                        className={`w-10 h-10 rounded-lg flex items-center justify-center ${getEventBgColor(event.type)}`}
                      >
                        <CalendarIcon
                          className={`w-5 h-5 ${getEventIconColor(event.type)}`}
                        />
                      </div>
                      <div className="flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          {event.title}
                        </p>
                        <p className="text-xs text-gray-500">
                          {new Date(event.startDate).toLocaleDateString()}
                          {event.endDate &&
                            event.endDate !== event.startDate &&
                            ` - ${new Date(event.endDate).toLocaleDateString()}`}
                          {event.time && ` at ${event.time}`}
                        </p>
                        {event.ownerName && (
                          <p className="text-xs text-gray-500">
                            Created by: {event.ownerName}
                          </p>
                        )}
                        {event.attendeeNames?.length > 0 && (
                          <p className="text-xs text-gray-500">
                            Attendees: {event.attendeeNames.join(", ")}
                          </p>
                        )}
                        {event.description && (
                          <p className="text-xs text-gray-500 mt-1">
                            {event.description}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <AddEventForm
        isOpen={showAddEventForm}
        onClose={() => setShowAddEventForm(false)}
        onEventAdded={handleEventAdded}
        teamMembers={teamMembers}
        auth={auth}
      />

      <DeleteConfirmationDialog
        isOpen={!!deleteEvent}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDeleteEvent}
        eventTitle={deleteEvent?.title || ""}
        isLoading={isDeleting}
      />
    </>
  );
};

export default TeamCalendarModal;
