import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Shield } from "lucide-react";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Badge } from "./ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import userImage from "./user.png";

const UserItem = (props) => {
  const [leadCount, setLeadCount] = useState(0);
  const [uploadCount, setUploadCount] = useState(0);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const status = props.status || "Offline";

  const statusColors = {
    Available: "bg-green-500",
    Away: "bg-yellow-500",
    Busy: "bg-red-500",
    Offline: "bg-gray-400",
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  useEffect(() => {
    const fetchUserActivity = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/activity/${props.id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
        );
        setLeadCount(responseData.lead_count || 0);
        if (props.api_account !== true) {
          setUploadCount(responseData.upload_count || 0);
        }
      } catch (error) {
        console.error("Error fetching user activity:", error);
      }
    };

    if (auth && auth.token) {
      fetchUserActivity();
    }
  }, [sendRequest, props.id, props.api_account, auth]);

  return (
    <li className="user-item">
      <Link to={`/${props.id}/leads`} className="block">
        <Card className="transition-all duration-300 hover:shadow-lg">
          <CardHeader className="pb-0 pt-4 px-4">
            <div className="flex justify-between items-start">
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <Avatar className="h-12 w-12 border-2 border-white">
                    <AvatarImage
                      src={props.image || userImage}
                      alt={props.username}
                    />
                    <AvatarFallback>
                      {getInitials(props.username)}
                    </AvatarFallback>
                  </Avatar>
                  {props.api_account !== true && (
                    <div
                      className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-white ${statusColors[status]}`}
                    />
                  )}
                </div>
                <div className="flex flex-col space-y-0">
                  <h3 className="font-semibold text-base mb-0 leading-none">
                    {props.username}
                  </h3>
                  <p className="text-sm text-gray-600 leading-tight mb-1">
                    {props.team}
                  </p>
                  {props.division && (
                    <div className="flex">
                      <Badge
                        variant={
                          props.division === "HQ" ? "default" : "secondary"
                        }
                        className="text-xs w-fit px-2 py-0.5"
                      >
                        {props.division}
                      </Badge>
                    </div>
                  )}
                </div>
              </div>
              {props.role === "admin" && (
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                      <div className="p-1 rounded-full hover:bg-gray-100 transition-colors">
                        <Shield className="text-blue-500" size={16} />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent
                      className="bg-blue-500 text-white font-medium px-3 py-1.5 text-sm rounded-md shadow-lg"
                      sideOffset={5}
                    >
                      <div className="flex items-center space-x-1">
                        <Shield size={14} />
                        <p>Admin User</p>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </CardHeader>

          <CardContent className="px-4 pb-4 pt-2">
            <div className="grid grid-cols-2 gap-2 bg-gray-50 rounded-lg p-2">
              <div className="text-center">
                <p className="font-bold text-blue-600 text-base leading-none mb-0.5">
                  {leadCount}
                </p>
                <p className="text-xs text-gray-600 leading-none">Leads</p>
              </div>
              {props.api_account !== true && (
                <div className="text-center">
                  <p className="font-bold text-green-600 text-base leading-none mb-0.5">
                    {uploadCount}
                  </p>
                  <p className="text-xs text-gray-600 leading-none">Uploads</p>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </Link>
    </li>
  );
};

export default UserItem;
