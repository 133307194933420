import React from "react";

const Button = React.forwardRef(({ className = "", variant = "default", size = "default", ...props }, ref) => {
  return (
    <button
      className={`inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
        ${variant === "default" ? "bg-blue-600 text-white hover:bg-blue-700" : ""}
        ${variant === "destructive" ? "bg-red-500 text-white hover:bg-red-600" : ""}
        ${variant === "outline" ? "border border-input bg-background hover:bg-accent hover:text-accent-foreground" : ""}
        ${variant === "secondary" ? "bg-gray-100 text-gray-900 hover:bg-gray-200" : ""}
        ${variant === "ghost" ? "hover:bg-accent hover:text-accent-foreground" : ""}
        ${variant === "link" ? "text-blue-600 underline-offset-4 hover:underline" : ""}
        ${size === "default" ? "h-10 px-4 py-2" : ""}
        ${size === "sm" ? "h-8 px-3" : ""}
        ${size === "lg" ? "h-12 px-8" : ""}
        ${className}`}
      ref={ref}
      {...props}
    />
  );
});

Button.displayName = "Button";

export { Button };
